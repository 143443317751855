import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { PbUserApi } from 'loopback';

@Injectable()
export class AuthRequiredGuard implements CanActivate {
  constructor(
    private router: Router,
    private userApi: PbUserApi
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const isAuthenticated = this.userApi.isAuthenticated();

    if (!isAuthenticated) {
      this.router.navigateByUrl('/login');
    }

    return isAuthenticated;
  }
}
