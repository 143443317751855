import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { RoleService } from 'common/services/role.service';
import { GlobalMenuItem } from 'common/interfaces/globalMenuItem.interface';
import * as _ from 'lodash';

@Injectable()
export class MenuService {

  protected currentMenuItem: GlobalMenuItem;

  constructor(
    private router: Router,
    private role: RoleService
  ) {}

  createMenu(config: GlobalMenuItem[], parent?: GlobalMenuItem): GlobalMenuItem[] {
    const items = [];

    config.forEach(item => {
      if (!this.role.can(item.caps)) return;

      const converted = this.convertItem(item, parent);

      if (converted.children && converted.children.length === 0) return;

      items.push(converted);
    });

    return this.sortItems(items);
  }

  getCurrentItem(): GlobalMenuItem {
    return this.currentMenuItem;
  }

  selectMenuItem(menuItems: GlobalMenuItem[]): GlobalMenuItem[] {
    const items = [];
    menuItems.forEach((item) => {
      this.selectItem(item);

      if (item.selected) {
        this.currentMenuItem = item;
      }

      if (item.children && item.children.length > 0) {
        item.children = this.selectMenuItem(item.children);
      }
      items.push(item);
    });
    return items;
  }

  protected sortItems(items: GlobalMenuItem[]): GlobalMenuItem[] {
    return items.sort((a, b): number => {
      if (a.order === undefined || b.order === undefined || a.order === b.order) {
        return 0;
      } else if (a.order < b.order) {
        return -1;
      } else if (a.order > b.order) {
        return 1;
      }
    });
  }

  protected convertItem(item: GlobalMenuItem, parent?: GlobalMenuItem): GlobalMenuItem {
    // we have to collect all paths to correctly build the url then
    let object = Object.assign({}, item);
    object.paths = parent && parent.paths ? parent.paths.slice(0) : [];
    object.paths.push(object.path);

    if (object.children && object.children.length > 0) {
      object.children = this.createMenu(object.children, object);
    }

    object = this.selectItem(object);

    // if current item is selected or expanded - then parent is expanded too
    if ((object.selected || object.expanded) && parent) {
      parent.expanded = true;
    }

    return object;
  }

  protected selectItem(object: GlobalMenuItem): GlobalMenuItem {
    const urlFragments = this.router.url.split('/').slice(1);
    const intersection = _.intersection(object.paths, urlFragments);

    object.selected = _.isEqual(object.paths, intersection);

    return object;
  }
}
