import { Component, ViewChild } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { News, NewsApi, FileInfo } from 'loopback';
import { DialogComponent } from 'common/components/dialog/dialog.component';

@Component({
  selector: 'pb-urgent-news-publisher',
  templateUrl: 'urgentNewsPublisher.component.html',
  styleUrls: ['urgentNewsPublisher.component.scss']
})
export class UrgentNewsPublisherComponent {

  news: News;
  newsForm: FormGroup;
  activeTab: string;
  currentType: string;

  images: FileInfo[] = [];

  isDelivering = false;
  isNew = false;

  @ViewChild('startConfirm') startConfirm: DialogComponent;
  @ViewChild('updateConfirm') updateConfirm: DialogComponent;
  @ViewChild('stopConfirm') stopConfirm: DialogComponent;

  constructor(
    public formBuilder: FormBuilder,
    public newsApi: NewsApi
  ) {
    this.newsForm = formBuilder.group({
      id: [undefined],
      level: ['low', Validators.required],
      title: [''],
      content: [''],
      embed: [''],
      expired: [false],
      publishedAt: [null],
      expiredAt: [null]
    });

    this.fetchNews();
  }

  fetchNews() {
    this.newsApi.findOne({order: 'createdAt DESC', include: 'image'}).subscribe(
      res => {
        if (res) {
          this.setFormValue(res);
          this.isNew = false;
        } else {
          this.isNew = true;
        }
      }
    );
  }

  setFormValue(news: News) {
    for (const prop in news) {
      if (!news.hasOwnProperty(prop)) continue;

      const control: AbstractControl = this.newsForm.controls[prop];
      const value = news[prop];

      if (control) {
        control.setValue(value);
      }
    }

    if (news.image) {
      this.images = [news.image];
    }

    this.isDelivering = !news.expired;
    this.currentType = news.type === 'embed' ? 'Embed' : (news.type === 'image' ? 'Image' : 'Text');
    this.activeTab = news.type || 'text';
  }

  addNew() {
    this.isNew = true;
    this.images = [];
    this.newsForm.reset({
      id: undefined,
      level: 'low',
      title: '',
      content: '',
      embed: '',
      expired: true
    });
  }

  submitNews() {
    const values = new News(this.newsForm.value);

    if (values.hasOwnProperty('id') && !values.id) {
      delete values.id;
    }

    values.imageId = this.images.length ? this.images[0].id : null;
    values.type = this.activeTab;

    this.newsApi.upsert(values).subscribe(
      res => this.setFormValue(res)
    );
  }

  deliverNews() {
    let confirm;

    if (this.newsForm.value.expired) {
      confirm = this.startConfirm.show();
    } else {
      confirm = this.updateConfirm.show();
    }

    confirm.subscribe(
      () => {
        this.newsForm.get('expired').setValue(false);
        this.newsForm.get('publishedAt').setValue(new Date());
        this.newsForm.get('expiredAt').setValue(null);
        this.isDelivering = true;
        this.submitNews();
      }
    );
  }

  stopNews() {
    this.stopConfirm.show().subscribe(
      () => {
        this.newsForm.get('expired').setValue(true);
        this.newsForm.get('expiredAt').setValue(new Date());
        this.isDelivering = false;
        this.submitNews();
      }
    );
  }
}
