/* tslint:disable */
import {
  Category
} from '../index';

declare var Object: any;
export interface TimeTableInterface {
  "kind": string;
  "start"?: Date|string;
  "end"?: Date|string;
  "embed"?: string;
  "insertionInterval"?: number;
  "id"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "categoryIds"?: Array<any>;
  "insertionCategoryIds"?: Array<any>;
  categories?: Category[];
  insertionCategories?: Category[];
}

export class TimeTable implements TimeTableInterface {
  "kind": string;
  "start": Date|string;
  "end": Date|string;
  "embed": string;
  "insertionInterval": number;
  "id": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "categoryIds": Array<any>;
  "insertionCategoryIds": Array<any>;
  categories: Category[];
  insertionCategories: Category[];
  constructor(data?: Partial<TimeTableInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `TimeTable`.
   */
  public static getModelName() {
    return "TimeTable";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of TimeTable for dynamic purposes.
  **/
  public static factory(data: TimeTableInterface): TimeTable{
    return new TimeTable(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'TimeTable',
      plural: 'TimeTables',
      path: 'TimeTables',
      idName: 'id',
      properties: {
        "kind": {
          name: 'kind',
          type: 'string'
        },
        "start": {
          name: 'start',
          type: 'Date|string'
        },
        "end": {
          name: 'end',
          type: 'Date|string'
        },
        "embed": {
          name: 'embed',
          type: 'string'
        },
        "insertionInterval": {
          name: 'insertionInterval',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "categoryIds": {
          name: 'categoryIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "insertionCategoryIds": {
          name: 'insertionCategoryIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
      },
      relations: {
        categories: {
          name: 'categories',
          type: 'Category[]',
          model: 'Category',
          relationType: 'referencesMany',
                  keyFrom: 'categoryIds',
          keyTo: 'id'
        },
        insertionCategories: {
          name: 'insertionCategories',
          type: 'Category[]',
          model: 'Category',
          relationType: 'referencesMany',
                  keyFrom: 'insertionCategoryIds',
          keyTo: 'id'
        },
      }
    }
  }
}
