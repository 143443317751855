import { Component, ViewChild } from '@angular/core';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { PbUserApi, PbUser, RoleGroupApi, RoleGroup, LoopBackFilter } from 'loopback';
import * as _ from 'lodash';
import { ListComponentBase } from 'common/classes/listComponentBase.class';
import { ListTableColumn } from 'common/interfaces/listTableColumn.interface';
import { ListTableSortOption } from 'common/interfaces/listTableSortOption.interface';
import { DialogComponent } from 'common/components/dialog/dialog.component';
import { debounceTime } from 'rxjs/operators';

@Component({
  templateUrl: 'userList.page.html'
})
export class UserListPage extends ListComponentBase {

  columns: ListTableColumn[] = [
    { name: 'name', title: 'Name', sortProperty: 'name' },
    { name: 'roleGroupId', title: 'Role Group', sortProperty: 'roleGroupId' }
  ];

  sortOption: ListTableSortOption = {
    column: 'name',
    order: 'asc'
  };

  list: PbUser[];
  roleGroups: RoleGroup[];

  groupFilter: AbstractControl;
  searchWord: AbstractControl;

  currentFilter: LoopBackFilter = {
    where: {}
  };

  @ViewChild('dialog') dialog: DialogComponent;

  constructor(
    public formBuilder: FormBuilder,
    public userApi: PbUserApi,
    public roleGroupApi: RoleGroupApi
  ) {
    super();

    this.groupFilter = formBuilder.control('');
    this.groupFilter.valueChanges.subscribe(
      val => this.filterByGroup(val)
    );

    this.searchWord = formBuilder.control('');
    this.searchWord.valueChanges.pipe(debounceTime(500)).subscribe(
      value => this.search(value)
    );

    this.roleGroupApi.find().subscribe(res => this.roleGroups = res);

    this.init();
  }

  fetchListData(page: number, requireCount: boolean = false): Observable<PbUser[]> {
    let source: Observable<PbUser[]>;
    const filter: LoopBackFilter = this.getPagingFilter(_.clone(this.currentFilter), page);
    let countSource: Observable<{count: number}>;

    source = this.userApi.find(filter);
    countSource = this.userApi.count(filter.where);

    return this.fetch(source, countSource, requireCount);
  }

  filterByGroup(groupId: string|number) {
    if (groupId) {
      this.currentFilter.where.roleGroupId = groupId;
    } else {
      delete this.currentFilter.where.roleGroupId;
    }

    this.fetchListData(1, true).subscribe();
  }

  search(word: string) {
    if (word) {
      this.currentFilter.where.or = [{name: {like: `.*${word}.*`}}, {username: {like: `.*${word}.*`}}];
    } else {
      delete this.currentFilter.where.or;
    }

    this.fetchListData(1, true).subscribe();
  }

  deleteSelected() {
    this.dialog.show().subscribe(
      result => {
        if (result) {
          super.deleteSelected();
        }
      }
    );
  }

  deleteById(id: number): Observable<any> {
    return this.userApi.deleteById(id);
  }
}
