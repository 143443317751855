import { Component, ViewChild, ViewEncapsulation, Input, Output, ElementRef, EventEmitter } from '@angular/core';

import './fullCalendar.loader.ts';
import * as jQuery from 'jquery';

@Component({
  selector: 'pb-full-calendar',
  templateUrl: 'fullCalendar.component.html',
  styleUrls: ['fullCalendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FullCalendarComponent {

  @Input() name: string;
  @Input() config: Object = {};
  @Input() events: any[];
  @Output() calendarReady = new EventEmitter<any>();
  @Output() eventsFetch = new EventEmitter<any>();
  @Output() eventRender = new EventEmitter<any>();
  @Output() eventClick = new EventEmitter<any>();
  @Output() dayClick = new EventEmitter<any>();
  @Output() select = new EventEmitter<any>();
  @Output() eventDragStart = new EventEmitter<any>();
  @Output() eventDragStop = new EventEmitter<any>();
  @Output() eventDrop = new EventEmitter<any>();
  @Output() eventResize = new EventEmitter<any>();
  @Output() navLinkDayClick = new EventEmitter<any>();
  @Output() navLinkWeekClick = new EventEmitter<any>();

  calendar: any;

  constructor(public el: ElementRef) {}

  render() { this.fcMethod('render'); }
  refetchEvents() { this.fcMethod('refetchEvents'); }
  removeEvents(idOrFilter: number|number[]|((event: any) => boolean)) { this.fcMethod('removeEvents', idOrFilter); }
  renderEvent(event: any, stick: boolean = false) { this.fcMethod('renderEvent', event, stick); }
  updateEvent(event: any) { this.fcMethod('updateEvent', event); }

  getOption(key: string) { return this.fcMethod('option', key); }
  setOption(key: string, value: any) { this.fcMethod('option', key, value); }

  fcMethod(method: string, ...args: any[]) {
    this.calendar.fullCalendar(method, ...args);
  }

  ngOnInit() {
    const name = this.name;

    if (this.events) {
      this.config['events'] = this.events;
    } else {
      this.config['events'] = (start, end, timezone, cb) => {
        this.eventsFetch.emit({start, end, timezone, cb, name});
      };
    }

    this.config['eventRender'] = (event, el) => {
      this.eventRender.emit({name, event, el});
    };
    this.config['eventClick'] = (event, jsEvent, view) => {
      this.eventClick.emit({name, event, jsEvent, view});
    };
    this.config['dayClick'] = (date, jsEvent, view) => {
      this.dayClick.emit({name, date, jsEvent, view});
    };
    this.config['select'] = (start, end, jsEvent, view) => {
      this.select.emit({name, start, end, jsEvent, view});
    };
    this.config['eventDragStart'] = (event, jsEvent, ui, view) => {
      this.eventDragStart.emit({name, event, jsEvent, ui, view});
    };
    this.config['eventDragStop'] = (event, jsEvent, ui, view) => {
      this.eventDragStop.emit({name, event, jsEvent, ui, view});
    };
    this.config['eventDrop'] = (event, delta, revertFn, jsEvent, ui, view) => {
      this.eventDrop.emit({name, event, delta, revertFn, jsEvent, ui, view});
    };
    this.config['eventResize'] = (event, delta, revertFn, jsEvent, ui, view) => {
      this.eventResize.emit({name, event, delta, revertFn, jsEvent, ui, view});
    };
    this.config['navLinkDayClick'] = (date, jsEvent) => {
      this.navLinkDayClick.emit({name, date, jsEvent});
    };
    this.config['navLinkWeekClick'] = (date, jsEvent) => {
      this.navLinkWeekClick.emit({name, date, jsEvent});
    };
  }

  ngAfterViewInit() {
    this.calendar = jQuery(this.el.nativeElement).fullCalendar(this.config);
    this.calendarReady.emit({calendar: this.calendar, name: this.name});
  }
}
