/* tslint:disable */
import {
  Category,
  FileInfo
} from '../index';

declare var Object: any;
export interface BannerInterface {
  "title"?: string;
  "url"?: string;
  "startDate"?: Date|string;
  "endDate"?: Date|string;
  "displayTime"?: number;
  "id"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "imageId"?: number;
  categories?: Category[];
  image?: FileInfo;
}

export class Banner implements BannerInterface {
  "title": string;
  "url": string;
  "startDate": Date|string;
  "endDate": Date|string;
  "displayTime": number;
  "id": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "imageId": number;
  categories: Category[];
  image: FileInfo;
  constructor(data?: Partial<BannerInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Banner`.
   */
  public static getModelName() {
    return "Banner";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Banner for dynamic purposes.
  **/
  public static factory(data: BannerInterface): Banner{
    return new Banner(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Banner',
      plural: 'Banners',
      path: 'Banners',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          type: 'string'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "startDate": {
          name: 'startDate',
          type: 'Date|string'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date|string'
        },
        "displayTime": {
          name: 'displayTime',
          type: 'number'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "imageId": {
          name: 'imageId',
          type: 'number'
        },
      },
      relations: {
        categories: {
          name: 'categories',
          type: 'Category[]',
          model: 'Category',
          relationType: 'hasMany',
          modelThrough: 'CategoryBanner',
          keyThrough: 'categoryId',
          keyFrom: 'id',
          keyTo: 'bannerId'
        },
        image: {
          name: 'image',
          type: 'FileInfo',
          model: 'FileInfo',
          relationType: 'belongsTo',
                  keyFrom: 'imageId',
          keyTo: 'id'
        },
      }
    }
  }
}
