import { NgModule } from '@angular/core';

import { PbCommonModule } from 'common/common.module';
import { coreRouting } from './core.routes';

import { LoginPage } from './pages/login/login.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { MediaPage } from './pages/media/media.page';
import { ChannelListPage } from './pages/channelList/channelList.page';
import { ChannelDetailPage } from './pages/channelDetail/channelDetail.page';
import { UserListPage } from './pages/userList/userList.page';
import { UserDetailPage } from './pages/userDetail/userDetail.page';
import { ClientListPage } from './pages/clientList/clientList.page';
import { ClientDetailPage } from './pages/clientDetail/clientDetail.page';
import { NewsListPage } from './pages/newsList/newsList.page';

@NgModule({
  imports: [
    PbCommonModule,
    coreRouting
  ],
  exports: [
    PbCommonModule
  ],
  declarations: [
    LoginPage, DashboardPage, MediaPage,
    ChannelListPage, ChannelDetailPage,
    UserListPage, UserDetailPage,
    ClientListPage, ClientDetailPage,
    NewsListPage
  ],
  providers: []
})
export class PbCoreModule {}
