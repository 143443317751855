/* tslint:disable */
import {
  FileInfo
} from '../index';

declare var Object: any;
export interface NewsInterface {
  "level"?: string;
  "type": string;
  "title"?: string;
  "content"?: string;
  "expired"?: boolean;
  "publishedAt"?: Date|string;
  "expiredAt"?: Date|string;
  "embed"?: string;
  "id"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "imageId"?: number;
  image?: FileInfo;
}

export class News implements NewsInterface {
  "level": string;
  "type": string;
  "title": string;
  "content": string;
  "expired": boolean;
  "publishedAt": Date|string;
  "expiredAt": Date|string;
  "embed": string;
  "id": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "imageId": number;
  image: FileInfo;
  constructor(data?: Partial<NewsInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `News`.
   */
  public static getModelName() {
    return "News";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of News for dynamic purposes.
  **/
  public static factory(data: NewsInterface): News{
    return new News(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'News',
      plural: 'News',
      path: 'News',
      idName: 'id',
      properties: {
        "level": {
          name: 'level',
          type: 'string'
        },
        "type": {
          name: 'type',
          type: 'string'
        },
        "title": {
          name: 'title',
          type: 'string'
        },
        "content": {
          name: 'content',
          type: 'string'
        },
        "expired": {
          name: 'expired',
          type: 'boolean',
          default: false
        },
        "publishedAt": {
          name: 'publishedAt',
          type: 'Date|string',
          default: new Date(0)
        },
        "expiredAt": {
          name: 'expiredAt',
          type: 'Date|string',
          default: new Date(0)
        },
        "embed": {
          name: 'embed',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "imageId": {
          name: 'imageId',
          type: 'number'
        },
      },
      relations: {
        image: {
          name: 'image',
          type: 'FileInfo',
          model: 'FileInfo',
          relationType: 'belongsTo',
                  keyFrom: 'imageId',
          keyTo: 'id'
        },
      }
    }
  }
}
