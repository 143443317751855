import { Pipe, PipeTransform } from '@angular/core';
import { ChannelHelperService } from '../services/channelHelper.service';

@Pipe({
  name: 'contentFileSize'
})
export class ContentFileSizePipe implements PipeTransform {

  constructor(private helper: ChannelHelperService) {}

  transform(content: any): number {
    return this.helper.getContentFileSize(content);
  }
}
