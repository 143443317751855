import {
  Component, ViewChild, ViewChildren, HostListener,
  EventEmitter, ElementRef, QueryList, Renderer
} from '@angular/core';

import { Observable, Subject, Subscription, timer, fromEvent } from 'rxjs';
import { FileInfoService } from 'common/services/fileInfo.service';
import { switchMap, take, tap } from 'rxjs/operators';

export type ArticleState = 'default'|'active'|'standby'|'leaving';

@Component({
  selector: 'pb-preview',
  templateUrl: 'preview.page.html',
  styleUrls: ['preview.page.scss']
})
export class PreviewPage {

  audioContext: AudioContext;
  audioElement: HTMLAudioElement;
  audioSource: MediaElementAudioSourceNode;

  item: any;
  showExternalLink: boolean = true;
  muted: boolean = false;
  type: string;
  textScrollTimer: number;
  textScrollCanceller: Function;

  @ViewChild('content') content: ElementRef;
  @ViewChild('video') video: ElementRef;
  @ViewChildren('slide') slides: QueryList<ElementRef>;

  state: ArticleState = 'default';

  get displayTime(): number { return (this.item.displayTime - 1) * 1000 || 3000; }
  videoPlaying: boolean = false;

  constructor(
    public imageUtil: FileInfoService,
    public el: ElementRef,
    public renderer: Renderer
  ) {
    this.item = JSON.parse(localStorage.getItem('pbPreviewData'));
    this.onWindowResize();
    this.audioContext = new (AudioContext || webkitAudioContext)();
  }

  ngAfterViewInit() {
    this.start();
  }

  ngOnDestroy() {
    this.resetSound();
  }

  start() {
    setTimeout(() => {
      this.activate().pipe(switchMap(() => this.leave())).subscribe(() => this.reset());
    }, 500);
  }

  activate(): Observable<any> {
    this.state = 'active';

    return this.setScroller();
  }

  leave(): Observable<any> {
    this.state = 'leaving';
    if (this.textScrollCanceller) this.textScrollCanceller();

    return timer(1000);
  }

  reset() {
    this.state = 'default';
    this.resetImageSlider();
    this.resetVideo();
    this.resetSound();
    this.resetTextScroller();
  }

  getState(): ArticleState { return this.state; }

  canPlaySound(): boolean {
    return this.item.mediaType === 'image' && this.item.sound;
  }

  setScroller(): Observable<any> {
    this.textScrollCanceller = this.enableTextScroller();

    if (this.video) {
      return this.initVideo();
    } else {
      this.initSound();

      return this.initImageSlider();
    }
  }

  initImageSlider(): Observable<any> {
    let currentIndex = 0;
    const intervalTime = this.displayTime / this.slides.length;
    const interval = setInterval(() => {
      const current = this.slides.toArray()[currentIndex];
      const next = this.slides.toArray()[++currentIndex];

      if (next) {
        next.nativeElement.style.opacity = '1';
        current.nativeElement.style.opacity = '0';
      } else {
        clearInterval(interval);
        currentIndex = 0;
      }
    }, intervalTime);

    return timer(this.displayTime);
  }

  resetImageSlider() {
    if (this.slides.length <= 1) return;

    this.slides.forEach((slide, i) => {
      const el = slide.nativeElement as HTMLLIElement;

      el.style.opacity = i === 0 ? '1' : '0';
    });
  }

  initVideo(): Observable<any> {
    if (!this.video) return;

    const video = this.video.nativeElement as HTMLVideoElement;
    // let onCanPlay: Observable<any> = Observable.fromEvent(video, 'canplay').take(1);
    const displayTime = this.item.video.duration * 1000;

    fromEvent(video, 'playing').pipe(take(1)).subscribe(_ => this.videoPlaying = true);

    // this.slideTimerSubscription = Observable.fromEvent(video, 'ended').take(1).subscribe(_ => {
    //   this.videoPlaying = false
    //   this.ended.emit()
    // })

    video.src = this.imageUtil.getUrl(this.item.video);

    return timer(displayTime).pipe(
      tap(_ => this.videoPlaying = false)
    );
  }

  initSound() {
    if (!this.canPlaySound()) return;

    this.audioElement = this.renderer.createElement(undefined, 'audio');
    this.renderer.setElementProperty(this.audioElement, 'src', this.imageUtil.getUrl(this.item.sound));
    this.renderer.setElementProperty(this.audioElement, 'crossOrigin', 'anonymous');
    this.audioSource = this.audioContext.createMediaElementSource(this.audioElement);
    this.audioSource.connect(this.audioContext.destination);
    this.renderer.invokeElementMethod(this.audioElement, 'play');

    // this.audioSource = this.audioContext.createBufferSource();
    // this.audioSource.connect(this.audioContext.destination);

    // this.audioFetchSubscription = this.http.get(this.imageUtil.getUrl(this.item.sound), {
    //   responseType: ResponseContentType.ArrayBuffer
    // }).subscribe(res => {
    //   this.audioContext.decodeAudioData(res.arrayBuffer(), e => {
    //     this.audioSource.buffer = e;
    //     this.audioSource.start();
    //   });
    // });
  }

  resetVideo() {
    if (!this.video) return;

    const video = this.video.nativeElement as HTMLVideoElement;
    video.src = '';
  }

  resetSound() {
    if (!this.canPlaySound() || !this.audioSource) return;

    this.audioElement = undefined;
    this.audioSource.disconnect();
    this.audioSource = undefined;

    // this.audioSource.disconnect();
    // this.audioSource = undefined;
  }

  enableTextScroller(): Function {
    if (!this.content) return () => void(0);

    const content = <HTMLElement>this.content.nativeElement;

    const self = this;
    const destination: number = content.parentElement.clientHeight - content.scrollHeight;
    const speed: number = 60;
    const delay: number = 5000;
    const duration: number = Math.abs(destination) / speed;
    console.log(content.parentElement.clientHeight, content.scrollHeight);

    if (destination >= 0) return () => void(0);

    function forward() {
      content.removeEventListener('transitionend', forward);

      self.textScrollTimer = window.setTimeout(() => {
        content.style.transition = 'transform ' + duration + 's linear';
        content.style.transform = 'translate3d(0,' + destination + 'px,0)';
      }, delay);

      content.addEventListener('transitionend', backward);
    }

    function backward() {
      content.removeEventListener('transitionend', backward);

      self.textScrollTimer = window.setTimeout(() => {
        content.style.transition = 'transform ' + duration / 10 + 's linear';
        content.style.transform = 'translate3d(0,0,0)';
      }, delay);

      content.addEventListener('transitionend', forward);
    }

    function canceller() {
      const translate = content.style.transform.match(/(-?[0-9\.]+)/g);

      content.removeEventListener('transitionend', forward);
      content.removeEventListener('transitionend', backward);
      content.style.transition = '';
      clearTimeout(self.textScrollTimer);

      if (translate) {
        content.style.transform = 'translate3d(' + translate[4] + 'px,' + translate[5] + 'px,0)';
      }
    }

    forward();

    return canceller;
  }

  resetTextScroller() {
    if (!this.content) return;

    this.content.nativeElement.style.transform = 'translate3d(0,0,0)';
  }

  replay() {
    this.start();
  }

  @HostListener('window:resize') onWindowResize() {
    const windowWidth = window.screen.width;
    const rootEl = document.documentElement;

    rootEl.style.fontSize = 16 * rootEl.clientWidth / 1980 + 'px';
  }
}
