import { Component, Input, HostBinding, SimpleChanges } from '@angular/core';
import { timer } from 'rxjs';
import { ToastType } from 'common/typeAliases';

@Component({
  selector: 'pb-toast',
  exportAs: 'pb-toast',
  templateUrl: 'toast.component.html',
  styleUrls: ['toast.component.scss']
})
export class ToastComponent {

  typeToIconClass = {
    default: '',
    error: 'fa-times-circle text-danger',
    warn: 'fa-exclamation-triangle text-warn',
    info: 'fa-info-circle text-info',
    success: 'fa-check-circle text-success'
  };

  iconClass: string;

  @Input() message: string = '';
  @Input() type: ToastType = 'default';
  @Input() autoHide: boolean = true;
  @Input() autoHideDelay: number = 5000;

  @HostBinding('class.shown') shown: boolean = false;

  show() {
    this.shown = true;

    if (this.autoHide) {
      timer(this.autoHideDelay).subscribe(_ => this.hide());
    }
  }

  hide() {
    this.shown = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['type']) {
      this.iconClass = this.typeToIconClass[changes['type'].currentValue];
    }
  }
}
