/* tslint:disable */
import { Injectable } from '@angular/core';
import { Client } from '../../models/Client';
import { PbUser } from '../../models/PbUser';
import { RoleGroup } from '../../models/RoleGroup';
import { Media } from '../../models/Media';
import { Channel } from '../../models/Channel';
import { News } from '../../models/News';
import { Article } from '../../models/Article';
import { Ad } from '../../models/Ad';
import { Banner } from '../../models/Banner';
import { TimeTable } from '../../models/TimeTable';
import { Category } from '../../models/Category';
import { FileInfo } from '../../models/FileInfo';
import { Config } from '../../models/Config';

export interface Models { [name: string]: any }

@Injectable()
export class SDKModels {

  private models: Models = {
    Client: Client,
    PbUser: PbUser,
    RoleGroup: RoleGroup,
    Media: Media,
    Channel: Channel,
    News: News,
    Article: Article,
    Ad: Ad,
    Banner: Banner,
    TimeTable: TimeTable,
    Category: Category,
    FileInfo: FileInfo,
    Config: Config,
    
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
