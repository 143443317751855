import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { CategoryApi, LoopBackFilter } from 'loopback';
import * as _ from 'lodash';
import { ListComponentBase } from './listComponentBase.class';
import { ListTableColumn } from '../interfaces/listTableColumn.interface';
import { ListTableSortOption } from '../interfaces/listTableSortOption.interface';
import { DialogComponent } from '../components/dialog/dialog.component';
import { tap } from 'rxjs/operators';

export class CategoryListComponentBase extends ListComponentBase {

  kind: string;
  itemsPerPage: number = 200;

  columns: ListTableColumn[] = [
    {
      name: 'name',
      title: 'Name',
      sortProperty: 'name'
    },
    {
      name: 'sequence',
      title: 'Post Order',
      sortProperty: 'sequence'
    }
  ];

  sortOption: ListTableSortOption = {
    column: 'name',
    order: 'asc'
  };

  currentFilter: LoopBackFilter = {
    where: {kind: undefined}
  };

  list: any[];

  createForm: FormGroup;
  updateForms: {[id: number]: FormGroup} = {};
  editingItem: number;

  sequenceTypes = [
    { name: 'Publication Start Date Desc', value: 'publicationStartDesc' },
    { name: 'Publication Start Date Asc', value: 'publicationStartAsc' },
    { name: 'Publication End Date Desc', value: 'publicationEndDesc' },
    { name: 'Publication End Date Asc', value: 'publicationEndAsc' }
  ];

  formBuilder: FormBuilder;
  categoryApi: CategoryApi;

  dialog: DialogComponent;

  init() {
    this.createForm = this.formBuilder.group({
      name: ['', Validators.required],
      sequence: ['publicationEndAsc', Validators.required],
      kind: [this.kind]
    });

    super.init();
  }

  fetchListData(page: number, requireCount: boolean = false): Observable<any> {
    this.currentFilter.where.kind = this.kind;

    const filter: LoopBackFilter = this.getPagingFilter(_.clone(this.currentFilter), page);

    const source = this.categoryApi.find(filter).pipe(
      tap(
        res => {
          this.updateForms = {};

          res.forEach(i => {
            this.updateForms[i.id] = this.formBuilder.group({
              id: [i.id, Validators.required],
              name: [i.name, Validators.required],
              sequence: [i.sequence, Validators.required],
              kind: [this.kind]
            });
          });
        },
        err => console.error(err)
      )
    );
    const countSource = this.categoryApi.count(filter.where);

    return this.fetch(source, countSource, requireCount);
  }

  createCategory() {
    if (this.createForm.valid) {
      this.categoryApi.create(this.createForm.value).subscribe(
        res => {
          this.fetchListData(1, true).subscribe();
        }
      );
    }
  }

  updateCategory(id: number) {
    const form = this.updateForms[id];

    if (form.valid) {
      this.categoryApi.updateAttributes(id, form.value).subscribe(
        res => {
          this.editingItem = undefined;
          this.fetchListData(1, true).subscribe();
        }
      );
    }
  }

  deleteSelected() {
    this.dialog.show().subscribe(
      result => {
        if (result) {
          super.deleteSelected();
        }
      }
    );
  }

  deleteById(id: number): Observable<any> {
    return this.categoryApi.deleteById(id);
  }
}
