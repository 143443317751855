import { Component, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';

import { GlobalEventService } from 'common/services/globalEvent.service';
import { ScrollContentBackTopConfig } from '../../interfaces/scrollContentBackTopConfig.interface';
import { ScrollContentInfiniteConfig } from '../../interfaces/scrollContentInfiniteConfig.interface';

@Component({
  selector: 'pb-scroll-content',
  templateUrl: 'scrollContent.component.html',
  styleUrls: ['scrollContent.component.scss']
})
export class ScrollContentComponent {

  backTopHelperStyle: any;
  backTopVisible: boolean = false;

  infiniteObserved: boolean = true;
  infinitePageCount: number = 1;

  @Input() backTopConfig: ScrollContentBackTopConfig = {
    thresholdTop: 200,
    showSpeed: 500,
    moveSpeed: 500
  };

  @Input() infiniteConfig: ScrollContentInfiniteConfig = {
    thresholdBottom: 200
  };

  @Output() infinite: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('scroller') scroller: ElementRef;
  @ViewChild('scrollContent') scrollContent: ElementRef;

  constructor(
    private globalEvent: GlobalEventService
  ) {
    this.globalEvent.on('scrollToTop').subscribe(() => this.scrollToTop());
  }

  onScroll(scrollTop: number) {
    const scrollerEl = this.scroller.nativeElement as HTMLElement;
    const scrollContentEl = this.scrollContent.nativeElement as HTMLElement;
    const distance = scrollContentEl.clientHeight - (scrollerEl.clientHeight + scrollTop);

    this.backTopVisible = scrollTop > this.backTopConfig.thresholdTop;

    if (this.infiniteObserved && distance <= this.infiniteConfig.thresholdBottom) {
      this.infiniteObserved = false;
      this.infinite.emit({
        page: ++this.infinitePageCount,
        callback: (continued: boolean) => {
          if (continued) this.infiniteObserved = true;
        }
      });
    }
  }

  scrollToTop() {
    const el = this.scroller.nativeElement as HTMLElement;
    const offset = el.scrollTop;

    this.backTopHelperStyle = { transition: '', marginTop: `-${offset}px`};
    el.scrollTop = 0;
    setTimeout(() => this.backTopHelperStyle = { transition: `margin-top ease ${this.backTopConfig.moveSpeed}ms`, marginTop: 0});
  }

  resetBackTopHelperState() {
    this.backTopHelperStyle = { transition: '', marginTop: 0};
  }

  resetInfiniteState() {
    this.infiniteObserved = true;
    this.infinitePageCount = 1;
  }

  ngAfterViewInit () {
    this.onScroll(0);
  }

  onBackTopClick(e: MouseEvent) {
    e.preventDefault();
    this.scrollToTop();
  }
}
