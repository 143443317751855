import { Component, Self } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AdApi, CategoryApi, Ad, FileInfo } from 'loopback';
import { ArticleDetailComponentBase } from 'common/classes/articleDetailComponentBase.class';
import { GlobalEventService } from 'common/services/globalEvent.service';

@Component({
  templateUrl: 'adDetail.page.html'
})
export class AdDetailPage extends ArticleDetailComponentBase<Ad> {

  images: FileInfo[] = [];
  video: FileInfo[] = [];
  sound: FileInfo[] = [];

  constructor(
    public formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public globalEvent: GlobalEventService,
    public adApi: AdApi,
    public categoryApi: CategoryApi
  ) {
    super();
    this.init();
  }

  fetchItem(id: number) {
    this.adApi.findOne({
      where: {id},
      include: ['categories', 'images', 'video', 'sound']
    }).subscribe(
      res => this.setFormValue(res),
      err => console.error(err)
    );
  }

  fetchCategories() {
    this.categoryApi.find({where: {kind: 'ad'}}).subscribe(
      res => this.allCategories = res,
      err => console.error(err)
    );
  }

  createNewModel(): Ad {
    return new Ad();
  }

  setFormValue(item: Ad) {
    super.setFormValue(item);

    if (item.images) {
      this.images = item.images;
    }

    if (item.video) {
      this.video = [item.video];
    }

    if (item.sound) {
      this.sound = [item.sound];
    }
  }

  preSubmit(values: any) {
    values.imageIds = this.images.length ? this.images.map(image => image.id) : null;
    values.videoId = this.video.length ? this.video[0].id : null;
    values.soundId = this.sound.length ? this.sound[0].id : null;
  }

  preview() {
    const values = this.form.value;

    values.images = this.images;
    values.video = this.video.length ? this.video[0] : undefined;
    values.sound = this.sound.length ? this.sound[0] : undefined;

    localStorage.setItem('pbPreviewData', JSON.stringify(values));
    window.open('#/tv/preview', 'pb-preview', 'toolbar=no,location=no,width=1280,height=720');
  }

  upsert(values: any): Observable<any> {
    return this.adApi.upsert(values);
  }

  deleteCategories(id: number): Observable<any> {
    return this.adApi.deleteCategories(id);
  }

  linkCategory(itemId: number, categoryId: number): Observable<any> {
    return this.adApi.linkCategories(itemId, categoryId);
  }

  getAfterSubmitRoute(id: number): any[] {
    return ['tv', 'ad', id];
  }
}
