/* tslint:disable */

declare var Object: any;
export interface RoleGroupInterface {
  "name": string;
  "roleAdmin"?: boolean;
  "roleUser"?: boolean;
  "roleMedia"?: boolean;
  "roleArticle"?: boolean;
  "roleChannel"?: boolean;
  "roleAd"?: boolean;
  "roleClient"?: boolean;
  "roleNews"?: boolean;
  "id"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
}

export class RoleGroup implements RoleGroupInterface {
  "name": string;
  "roleAdmin": boolean;
  "roleUser": boolean;
  "roleMedia": boolean;
  "roleArticle": boolean;
  "roleChannel": boolean;
  "roleAd": boolean;
  "roleClient": boolean;
  "roleNews": boolean;
  "id": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  constructor(data?: Partial<RoleGroupInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `RoleGroup`.
   */
  public static getModelName() {
    return "RoleGroup";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of RoleGroup for dynamic purposes.
  **/
  public static factory(data: RoleGroupInterface): RoleGroup{
    return new RoleGroup(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'RoleGroup',
      plural: 'RoleGroups',
      path: 'RoleGroups',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "roleAdmin": {
          name: 'roleAdmin',
          type: 'boolean',
          default: false
        },
        "roleUser": {
          name: 'roleUser',
          type: 'boolean',
          default: false
        },
        "roleMedia": {
          name: 'roleMedia',
          type: 'boolean',
          default: false
        },
        "roleArticle": {
          name: 'roleArticle',
          type: 'boolean',
          default: false
        },
        "roleChannel": {
          name: 'roleChannel',
          type: 'boolean',
          default: false
        },
        "roleAd": {
          name: 'roleAd',
          type: 'boolean',
          default: false
        },
        "roleClient": {
          name: 'roleClient',
          type: 'boolean',
          default: false
        },
        "roleNews": {
          name: 'roleNews',
          type: 'boolean',
          default: false
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
      },
      relations: {
      }
    }
  }
}
