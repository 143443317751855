import { Injectable } from '@angular/core';
import { FileInfo, LoopBackAuth, LoopBackConfig } from 'loopback';
import * as _ from 'lodash';

@Injectable()
export class FileInfoService {

  constructor(private auth: LoopBackAuth) {}

  getUrl(fileObj: FileInfo, size: string = 'full'): string {
    if (_.isEmpty(fileObj)) {
      return '';
    }

    const filename = size === 'thumb' ? fileObj.thumbname : fileObj.filename;
    const url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
      `/Media/${fileObj.container}/download/${filename}?access_token=${this.auth.getAccessTokenId()}`;

    return url;
  }
}
