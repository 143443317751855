import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileInfoApi, SDKModels, LoopBackAuth, ErrorHandler, LoopBackConfig } from 'loopback';
import { SocketConnection } from 'loopback/sockets/socket.connections';
import { filter, map, catchError } from 'rxjs/operators';

@Injectable()
export class FileInfoApiOverride extends FileInfoApi {

  constructor(
    protected http: HttpClient,
    protected connection: SocketConnection,
    protected models: SDKModels,
    protected auth: LoopBackAuth,
    @Optional() protected errorHandler: ErrorHandler
  ) {
    super(http, connection, models, auth, errorHandler);
  }

  public upload(formData: FormData): Observable<any> {
    const method = 'POST';
    const url = LoopBackConfig.getPath() + '/' + LoopBackConfig.getApiVersion() +
    '/FileInfos/upload';
    let headers = new HttpHeaders();

    headers = this.authenticate(url, headers);

    const request = new HttpRequest(method, url, formData, {headers});
    return this.http.request(request).pipe(
      filter(event => event instanceof HttpResponse),
      map((response: HttpResponse<any>) => response.body),
      catchError(e => this.errorHandler.handleError(e))
    );
  }
}
