import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoryApi, Category, LoopBackFilter } from 'loopback';
import { FileInfoService } from 'common/services/fileInfo.service';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class ChannelHelperService {

  private contentsCache: {[date: string]: {[id: number]: any[]}} = {};

  constructor(private categoryApi: CategoryApi, private fileInfoService: FileInfoService) {}

  getCategoryContentsOfDay(category: Category, date: string): Observable<any[]> {
    // if (this.contentsCache[date] && this.contentsCache[date][category.id]) {
    //   return Observable.of(this.contentsCache[date][category.id]);
    // }

    let method: 'getArticles'|'getAds'|'getBanners';
    let include: string[];
    const filter: LoopBackFilter = {
      where: {
        startDate: {lte: date},
        endDate: {gte: date}
      }
    };

    switch (category.kind) {
      case 'article':
      method = 'getArticles';
      include = ['images', 'video', 'sound'];
      break;

      case 'ad':
      method = 'getAds';
      include = ['images', 'video', 'sound'];
      break;

      case 'banner':
      method = 'getBanners';
      include = ['image'];
      break;

      default: break;
    }

    if (include) {
      filter.include = include;
    }

    return this.categoryApi[method](category.id, filter).pipe(
      tap(res => {
        if (!this.contentsCache[date]) {
          this.contentsCache[date] = {};
        }
        this.contentsCache[date][category.id] = res;
      })
    );
  }

  getContentDuration(item: any): number {
    if (item.mediaType && item.mediaType === 'video') {
      return +item.video.duration;
    } else {
      return item.displayTime;
    }
  }

  getContentFileSize(item: any): number {
    if (item.video) {
      return item.video.size;
    }

    let size = 0;
    if (item.images && item.images.length > 0) {
      size += item.images.map(image => image.size).reduce((prev, curr) => prev + curr);
    } else if (item.image) {
      size += item.image.size;
    }
    if (item.sound) {
      size += item.sound.size;
    }
    return size;
  }

  getCategoryDuration(category: Category, date: string): Observable<number> {
    return this.getCategoryContentsOfDay(category, date).pipe(
      map(res => this.getContentsTotalDuration(res))
    );
  }

  getCategoryFileSize(category: Category, date: string): Observable<number> {
    return this.getCategoryContentsOfDay(category, date).pipe(
      map(res => this.getContentsTotalFileSize(res))
    );
  }

  getContentsTotalDuration(contents: any[]): number {
    if (contents.length === 0) {
      return 0;
    }

    const durations = contents.map(item => this.getContentDuration(item));

    return durations.reduce((prev, curr) => prev + curr);
  }

  getContentsTotalFileSize(contents: any[]): number {
    if (contents.length === 0) {
      return 0;
    }

    const sizes = contents.map(item => this.getContentFileSize(item));

    return sizes.reduce((prev, curr) => prev + curr);
  }

  applyCalendarEventProp(item: Object): any {
    if (item['embed']) {
      item['backgroundColor'] = '#d9eff9';
      item['borderColor'] = '#528ace';
      item['title'] = item['embed'];
    } else {
      if (item['categories'] && item['categories'].length) {
        item['title'] = item['categories'].filter(category => !!category).map(category => category.name).join(' / ');
      }
    }

    return item;
  }

  clearContentsCache() {
    this.contentsCache = {};
  }
}
