import { Injectable } from '@angular/core';
import { RoleGroup } from 'loopback';

@Injectable()
export class RoleService {

  private role: RoleGroup;

  constructor() {
    this.role = JSON.parse(localStorage.getItem('pbRole'));
  }

  setRole(role: RoleGroup) {
    this.role = role;
    localStorage.setItem('pbRole', JSON.stringify(role));
  }

  clear() {
    this.role = undefined;
    localStorage.removeItem('pbRole');
  }

  can(caps: string[]): boolean {
    if (!this.role) return false;
    if (!caps || this.role.roleAdmin) return true;

    return caps.every(cap => {
      const prop: string = 'role' + cap.charAt(0).toUpperCase() + cap.substr(1);
      return this.role.hasOwnProperty(prop) && this.role[prop];
    });
  }
}
