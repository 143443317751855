import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { Observable, forkJoin, of } from 'rxjs';
import { Category } from 'loopback';
import * as _ from 'lodash';
import { ChannelCategoryMeta } from '../../interfaces/channelCategoryMeta.interface';
import { ChannelHelperService } from '../../services/channelHelper.service';
import { startWith, tap } from 'rxjs/operators';

@Component({
  selector: 'pb-category-list-builder',
  templateUrl: 'categoryListBuilder.component.html',
  styleUrls: ['categoryListBuilder.component.scss']
})
export class CategoryListBuilderComponent {

  allCategories: Category[];

  categoriesForm: FormArray;

  categoriesDuration: number[] = [];
  categoriesDurationTotal: number = 0;

  movingItemIndex: number;
  moveoverItemIndex: number;

  @Input() form: FormGroup;
  @Input() arrayName: string;
  @Input() categoryGroup: ChannelCategoryMeta[];

  private disableObserve: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private helper: ChannelHelperService
  ) {}

  ngOnInit() {
    let prevIds: number[] = [];
    let date: string = this.form.get('start').value.split('T')[0];

    if (date === '1970-01-01') {
      date = new Date().toISOString().split('T')[0];
    }

    this.categoriesForm = this.form.get(this.arrayName) as FormArray;
    this.allCategories = _.chain(this.categoryGroup).map(g => g.categories).flatten<Category>().value();

    this.categoriesForm.valueChanges.pipe(startWith(this.categoriesForm.value)).subscribe(
      ids => {
        if (this.disableObserve) return;

        const joiners: Observable<number>[] = [];
        let sequence: Observable<number[]>;

        ids.forEach((id, i) => {
          const category = this.allCategories.find(cat => cat.id === +id);
          if (category && prevIds[i] !== +id) {
            joiners.push(
              this.helper.getCategoryDuration(category, date).pipe(
                tap(res => this.categoriesDuration[i] = res)
              )
            );
          }
        });

        sequence = joiners.length ? forkJoin(joiners) : of([0]);
        sequence.subscribe(
          res => {
            prevIds = ids.map(id => +id);
            this.calculateCategoryDurationTotal();
          }
        );
      }
    );
  }

  addCategorySelector() {
    this.categoriesForm.push(this.formBuilder.control(undefined));
  }

  removeCategorySelector(i) {
    this.categoriesDuration.splice(i, 1);
    this.categoriesForm.removeAt(i);
  }

  calculateCategoryDurationTotal() {
    if (this.categoriesDuration.length) {
      this.categoriesDurationTotal = this.categoriesDuration.reduce((prev, curr) => prev + curr);
    }
  }

  isCategorySelected(id: number): boolean {
    return this.categoriesForm.value.some(val => +val === id);
  }

  moveStart(e: DragEvent, i: number): void {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.dropEffect = 'move';
    this.movingItemIndex = i;
  }

  moveEnd(e: DragEvent, i: number): void {
    e.stopPropagation();
    this.movingItemIndex = undefined;
    this.moveoverItemIndex = undefined;
  }

  moveEnter(e: DragEvent, i: number): void {
    e.stopPropagation();
    e.preventDefault();
    this.moveoverItemIndex = i;
  }

  moveOver(e: DragEvent, i: number): void {
    e.stopPropagation();
    e.preventDefault();
  }

  moveLeave(e: DragEvent, i: number): void {
    e.stopPropagation();
    e.preventDefault();
  }

  moveDrop(e: DragEvent, i: number): void {
    const targetValue = this.categoriesForm.at(this.movingItemIndex).value;

    if (this.movingItemIndex < i) i--;

    e.stopPropagation();
    this.disableObserve = true;
    this.categoriesForm.removeAt(this.movingItemIndex);
    this.disableObserve = false;

    if (i === -1) {
      this.categoriesForm.push(this.formBuilder.control(targetValue));
    } else {
      this.categoriesForm.insert(i, this.formBuilder.control(targetValue));
    }
    this.movingItemIndex = undefined;
    this.moveoverItemIndex = undefined;
  }
}
