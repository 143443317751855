import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { ChannelListPage } from './pages/channelList/channelList.page';
import { ChannelDetailPage } from './pages/channelDetail/channelDetail.page';
import { MediaPage } from './pages/media/media.page';
import { UserListPage } from './pages/userList/userList.page';
import { UserDetailPage } from './pages/userDetail/userDetail.page';
import { ClientListPage } from './pages/clientList/clientList.page';
import { ClientDetailPage } from './pages/clientDetail/clientDetail.page';
import { AuthRequiredGuard } from 'common/guards/authRequired.guard';
import { NewsListPage } from './pages/newsList/newsList.page';

export const coreRoutes: Routes = [
  { path: 'login', component: LoginPage },
  { path: 'dashboard', component: DashboardPage, canActivate: [AuthRequiredGuard] },
  { path: 'news', component: NewsListPage, canActivate: [AuthRequiredGuard] },
  { path: 'channel', canActivate: [AuthRequiredGuard],
    children: [
      { path: 'index', component: ChannelListPage },
      { path: 'new', component: ChannelDetailPage },
      { path: ':id', component: ChannelDetailPage }
    ]
  },
  { path: 'media', component: MediaPage, canActivate: [AuthRequiredGuard] },
  { path: 'user', canActivate: [AuthRequiredGuard],
    children: [
      { path: 'index', component: UserListPage },
      { path: 'new', component: UserDetailPage },
      { path: ':id', component: UserDetailPage }
    ]
  },
  { path: 'client', canActivate: [AuthRequiredGuard],
    children: [
      { path: 'index', component: ClientListPage },
      { path: 'new', component: ClientDetailPage },
      { path: ':id', component: ClientDetailPage }
    ]
  }
];

export const coreRouting: ModuleWithProviders = RouterModule.forChild(coreRoutes);
