import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PbUserApi, PbUser, RoleGroupApi, RoleGroup } from 'loopback';
import { ToastComponent } from 'common/components/toast/toast.component';
import { equalValidator } from 'common/validators/equal.validator';

@Component({
  templateUrl: 'userDetail.page.html'
})
export class UserDetailPage {

  id: number;
  form: FormGroup;
  roleGroups: RoleGroup[];
  message: {type: string, text: string} = {type: undefined, text: undefined};

  messages: {[code: string]: {type: string, text: string}} = {
    created: {type: 'success', text: 'Successfully created.'},
    updated: {type: 'success', text: 'Successfully updated.'},
    error: {type: 'error', text: 'Server error'}
  };

  isNew: boolean = false;
  isCreated: boolean = false;

  @ViewChild('messageToast') messageToast: ToastComponent;

  constructor(
    public formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public userApi: PbUserApi,
    public roleGroupApi: RoleGroupApi
  ) {
    const { password, passwordConfirm } = this.createConfirmablePasswordControl();

    this.form = this.formBuilder.group({
      id: [undefined],
      name: ['', Validators.required],
      username: ['', Validators.required],
      email: [''],
      password,
      passwordConfirm,
      roleGroupId: ['']
    });

    this.route.params.subscribe(params => {
      const id = params['id'];

      this.isCreated = params['created'];

      if (id) {
        this.id = id;
        this.userApi.findById(id).subscribe(
          res => this.setFormValue(res)
        );
      } else {
        this.isNew = true;
        password.setValidators([password.validator, Validators.required]);
        passwordConfirm.setValidators([passwordConfirm.validator, Validators.required]);
        this.setFormValue(new PbUser());
      }
    });

    this.fetchRoleGroups();
  }

  ngAfterViewInit() {
    if (this.isCreated) {
      this.showMessage('created');
    }
  }

  fetchRoleGroups() {
    this.roleGroupApi.find().subscribe(
      res => this.roleGroups = res,
      err => console.error(err)
    );
  }

  setFormValue(user: PbUser) {
    for (const prop in user) {
      if (!user.hasOwnProperty(prop)) continue;

      const control: AbstractControl = this.form.controls[prop];

      if (!control) continue;

      if (prop === 'username' && !this.isNew) {
        control.disable();
      }

      control.setValue(user[prop]);
    }
  }

  upsertUser() {
    const values = this.form.value;

    if (values.password === '') {
      delete values.password;
    }

    delete values.passwordConfirm;

    this.userApi.upsert(values).subscribe(
      res => {
        if (this.isNew) {
          this.router.navigate(['user', res.id, {created: true}]);
        } else {
          this.showMessage('updated');
        }
      },
      err => {
        this.showMessage('error');
      }
    );
  }

  createConfirmablePasswordControl(): {password: FormControl, passwordConfirm: FormControl} {
    const password: FormControl = this.formBuilder.control('');
    const passwordConfirm = this.formBuilder.control('');

    password.setValidators(equalValidator(passwordConfirm));
    passwordConfirm.setValidators(equalValidator(password));

    password.valueChanges.subscribe(
      value => passwordConfirm.updateValueAndValidity({emitEvent: false})
    );
    passwordConfirm.valueChanges.subscribe(
      value => password.updateValueAndValidity({emitEvent: false})
    );

    return { password, passwordConfirm };
  }

  showMessage(result: string) {
    this.message = this.messages[result];
    this.messageToast.show();
  }
}
