import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SDKBrowserModule, FileInfoApi } from 'loopback';

import { TabsModule, ModalModule, AlertModule, PaginationModule, BsDropdownModule, TooltipModule } from 'ngx-bootstrap';
import { CKEditorModule } from 'ng2-ckeditor';
import { I18nModule } from '../i18n';

import { DYNAMIC_CHANNEL_EDITORS } from './tokens';
import { CardComponent } from './components/card/card.component';
import { ModalComponent } from './components/modal/modal.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ToastComponent } from './components/toast/toast.component';
import { ScrollContentComponent } from './components/scrollContent/scrollContent.component';
import { ContentTopComponent } from './components/contentTop/contentTop.component';
import { FullCalendarComponent } from './components/fullCalendar/fullCalendar.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuItemComponent } from './components/menuItem/menuItem.component';
import { PageTopComponent } from './components/pageTop/pageTop.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ListTableFilterComponent } from './components/listTableFilter/listTableFilter.component';
import { ListTableSortComponent } from './components/listTableSort/listTableSort.component';
import { MediaSelectorComponent } from './components/mediaSelector/mediaSelector.component';
import { ProgramEditorComponent } from './components/programEditor/programEditor.component';
import { ChannelContentsListComponent } from './components/channelContentsList/channelContentsList.component';
import { UrgentNewsPublisherComponent } from './components/urgentNewsPublisher/urgentNewsPublisher.component';
import { NotificationSenderComponent } from './components/notificationSender/notificationSender.component';
import { CategoryListBuilderComponent } from './components/categoryListBuilder/categoryListBuilder.component';
import { LookupNamePipe } from './pipes/lookupName.pipe';
import { CollectionDiffPipe } from './pipes/collectionDiff.pipe';
import { FileUrlPipe } from './pipes/fileUrl.pipe';
import { FileSizePipe } from './pipes/fileSize.pipe';
import { ContentDurationPipe } from './pipes/contentDuration.pipe';
import { ContentFileSizePipe } from './pipes/contentFileSize.pipe';
import { DateAsUtcPipe } from './pipes/dateAsUtc.pipe';
import { DurationPipe } from './pipes/duration.pipe';
import { ImageLoaderService } from './services/imageLoader.service';
import { ThemePreloaderService } from './services/themePreloader.service';
import { AppLoadingSpinnerService } from './services/appLoadingSpinner.service';
import { GlobalEventService } from './services/globalEvent.service';
import { GlobalStateService } from './services/globalState.service';
import { FileInfoService } from './services/fileInfo.service';
import { ChannelHelperService } from './services/channelHelper.service';
import { RoleService } from './services/role.service';
import { EnvironmentService } from './services/environment.service';
import { FileInfoApiOverride } from './services/fileApiOverride.service';
import { AuthRequiredGuard } from './guards/authRequired.guard';

const COMPONENTS = [
  CardComponent, ModalComponent, DialogComponent, ToastComponent, ScrollContentComponent, ContentTopComponent,
  FullCalendarComponent, MenuComponent, MenuItemComponent, PageTopComponent, SidebarComponent,
  ListTableFilterComponent, ListTableSortComponent, MediaSelectorComponent,
  ProgramEditorComponent, ChannelContentsListComponent, UrgentNewsPublisherComponent, NotificationSenderComponent,
  CategoryListBuilderComponent
];

const DIRECTIVES = [

];

const PIPES = [
  LookupNamePipe, CollectionDiffPipe, FileUrlPipe, FileSizePipe,
  ContentDurationPipe, ContentFileSizePipe, DateAsUtcPipe, DurationPipe
];

const SERVICES = [
  ImageLoaderService, ThemePreloaderService, AppLoadingSpinnerService,
  GlobalEventService, GlobalStateService, FileInfoService, ChannelHelperService, RoleService,
  EnvironmentService,
  { provide: DYNAMIC_CHANNEL_EDITORS, useValue: null, multi: true },
  { provide: FileInfoApi, useClass: FileInfoApiOverride }
];

const GUARDS = [
  AuthRequiredGuard
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    CKEditorModule,
    SDKBrowserModule.forRoot(),
    I18nModule
  ],
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TabsModule,
    ModalModule,
    AlertModule,
    PaginationModule,
    BsDropdownModule,
    TooltipModule,
    CKEditorModule,
    SDKBrowserModule,
    I18nModule,

    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES
  ],
  providers: [
    ...SERVICES,
    ...GUARDS
  ]
})
export class PbCommonModule {}
