import { GlobalMenuItem } from 'common/interfaces/globalMenuItem.interface';

export const menuConfig: GlobalMenuItem[] = [
  { path: 'dashboard', title: 'Dashboard', caps: ['news'], icon: 'fa fa-tachometer' },
  { path: 'channel', title: 'Channels', caps: ['channel'], icon: 'fa fa-th-large',
    children: [
      { path: 'index', title: 'All Channels', icon: 'fa fa-bars' },
      { path: 'new', title: 'New Channel', icon: 'fa fa-plus' }
    ]
  },
  { path: 'tv', title: 'TV', icon: 'fa fa-desktop',
    children: [
      { path: 'article', title: 'Articles', caps: ['article'],
        children: [
          { path: 'index', title: 'All Articles', icon: 'fa fa-bars' },
          { path: 'new', title: 'New Article', icon: 'fa fa-plus' },
          { path: 'category', title: 'Article Category', icon: 'fa fa-folder' }
        ]
      },
      { path: 'ad', title: 'Ads', caps: ['ad'],
        children: [
          { path: 'index', title: 'All Ads', icon: 'fa fa-bars' },
          { path: 'new', title: 'New Ad', icon: 'fa fa-plus' },
          { path: 'category', title: 'Ad Category', icon: 'fa fa-folder' }
        ]
      },
      { path: 'banner', title: 'Banners', caps: ['ad'],
        children: [
          { path: 'index', title: 'All Banners', icon: 'fa fa-bars' },
          { path: 'new', title: 'New Banner', icon: 'fa fa-plus' },
          { path: 'category', title: 'Banner Category', icon: 'fa fa-folder' }
        ]
      }
    ]
  },
  { path: 'media', title: 'Media', caps: ['media'], icon: 'fa fa-picture-o' },
  { path: 'user', title: 'Users', caps: ['user'], icon: 'fa fa-user',
    children: [
      { path: 'index', title: 'All Users', icon: 'fa fa-bars' },
      { path: 'new', title: 'New User', icon: 'fa fa-plus' }
    ]
  },
  { path: 'client', title: 'Clients', caps: ['client'], icon: 'fa fa-key',
    children: [
      { path: 'index', title: 'All Clients', icon: 'fa fa-bars' },
      { path: 'new', title: 'New Client', icon: 'fa fa-plus' }
    ]
  }
];
