import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PbUserApi, RoleGroupApi } from 'loopback';
import { ToastComponent } from 'common/components/toast/toast.component';
import { RoleService } from 'common/services/role.service';
import { mergeMap, tap } from 'rxjs/operators';

@Component({
  templateUrl: 'login.page.html',
  styleUrls: ['login.page.scss']
})
export class LoginPage {

  logoImage: string = require('../../../../assets/img/logo.svg');
  form: FormGroup;
  submitted: boolean = false;
  failedMessage: string;

  @ViewChild('messageToast') messageToast: ToastComponent;

  constructor(
    public formBuilder: FormBuilder,
    public userApi: PbUserApi,
    public roleGroupApi: RoleGroupApi,
    public role: RoleService,
    public router: Router
  ) {
    this.form = formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit(values: Object): void {
    this.submitted = true;
    this.userApi.login(values).pipe(
      mergeMap(token => this.roleGroupApi.findById(token.user.roleGroupId)),
      tap(role => this.role.setRole(role))
    ).subscribe(
      res => {
        this.router.navigateByUrl('/dashboard');
      },
      err => {
        this.failedMessage = err.status === 401
          ? 'Login failed. User name or password is incorrect.'
          : 'Server connection failed.';
        this.messageToast.show();
      }
    );
  }
}
