/* tslint:disable */
import {
  Category,
  FileInfo
} from '../index';

declare var Object: any;
export interface ArticleInterface {
  "title"?: string;
  "content"?: string;
  "startDate"?: Date|string;
  "endDate"?: Date|string;
  "displayTime"?: number;
  "url"?: string;
  "mediaType"?: string;
  "buttonText"?: string;
  "id"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "imageIds"?: Array<any>;
  "videoId"?: number;
  "soundId"?: number;
  categories?: Category[];
  images?: FileInfo[];
  video?: FileInfo;
  sound?: FileInfo;
}

export class Article implements ArticleInterface {
  "title": string;
  "content": string;
  "startDate": Date|string;
  "endDate": Date|string;
  "displayTime": number;
  "url": string;
  "mediaType": string;
  "buttonText": string;
  "id": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "imageIds": Array<any>;
  "videoId": number;
  "soundId": number;
  categories: Category[];
  images: FileInfo[];
  video: FileInfo;
  sound: FileInfo;
  constructor(data?: Partial<ArticleInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Article`.
   */
  public static getModelName() {
    return "Article";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Article for dynamic purposes.
  **/
  public static factory(data: ArticleInterface): Article{
    return new Article(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Article',
      plural: 'Articles',
      path: 'Articles',
      idName: 'id',
      properties: {
        "title": {
          name: 'title',
          type: 'string'
        },
        "content": {
          name: 'content',
          type: 'string'
        },
        "startDate": {
          name: 'startDate',
          type: 'Date|string'
        },
        "endDate": {
          name: 'endDate',
          type: 'Date|string'
        },
        "displayTime": {
          name: 'displayTime',
          type: 'number'
        },
        "url": {
          name: 'url',
          type: 'string'
        },
        "mediaType": {
          name: 'mediaType',
          type: 'string'
        },
        "buttonText": {
          name: 'buttonText',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "imageIds": {
          name: 'imageIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "videoId": {
          name: 'videoId',
          type: 'number'
        },
        "soundId": {
          name: 'soundId',
          type: 'number'
        },
      },
      relations: {
        categories: {
          name: 'categories',
          type: 'Category[]',
          model: 'Category',
          relationType: 'hasMany',
          modelThrough: 'CategoryArticle',
          keyThrough: 'categoryId',
          keyFrom: 'id',
          keyTo: 'articleId'
        },
        images: {
          name: 'images',
          type: 'FileInfo[]',
          model: 'FileInfo',
          relationType: 'referencesMany',
                  keyFrom: 'imageIds',
          keyTo: 'id'
        },
        video: {
          name: 'video',
          type: 'FileInfo',
          model: 'FileInfo',
          relationType: 'belongsTo',
                  keyFrom: 'videoId',
          keyTo: 'id'
        },
        sound: {
          name: 'sound',
          type: 'FileInfo',
          model: 'FileInfo',
          relationType: 'belongsTo',
                  keyFrom: 'soundId',
          keyTo: 'id'
        },
      }
    }
  }
}
