import { NgModule, ApplicationRef, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { removeNgStyles, createNewHosts, createInputTransfer } from '@angularclass/hmr';

import { AppComponent } from './app.component';
import { appRouting } from './app.routes';
import { environment } from '../env/env';
import { PbCoreModule } from './core/core.module';
import { PbTvPluginModule } from './plugins/tv/tv.module';

@NgModule({
  imports: [
    BrowserModule,
    appRouting,
    PbCoreModule,
    PbTvPluginModule
  ],
  declarations: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: environment.defaultLanguage }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(public appRef: ApplicationRef) {}

  hmrOnInit(store) {
    if (!store || !store.state) return;

    if ('restoreInputValues' in store) {
      store.restoreInputValues();
    }

    this.appRef.tick();

    delete store.state;
    delete store.restoreInputValues;
  }

  hmrOnDestroy(store) {
    const cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);

    store.state = {};
    store.disposeOldHosts = createNewHosts(cmpLocation);
    store.restoreInputValues = createInputTransfer();

    removeNgStyles();
  }

  hmrAfterDestroy(store) {
    store.disposeOldHosts();

    delete store.disposeOldHosts;
  }
}
