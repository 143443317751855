import { Component, Input, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'pb-dialog',
  exportAs: 'pb-dialog',
  templateUrl: 'dialog.component.html'
})
export class DialogComponent {

  @Input() type: 'alert'|'confirm' = 'alert';
  @Input() title: string = '';
  @Input() description: string = '';
  @ViewChild('dialog') dialog: ModalComponent;

  private subject: Subject<boolean>;

  show(): Observable<boolean> {
    this.dialog.show();
    this.subject = new Subject<boolean>();

    return this.subject.asObservable();
  }

  hide() {
    this.dialog.hide();
    this.subject = undefined;
  }

  onClickOk() {
    this.subject.next(true);
    this.hide();
  }
}
