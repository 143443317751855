import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'pb-list-table-filter',
  templateUrl: 'listTableFilter.component.html',
  styleUrls: ['listTableFilter.component.scss']
})
export class ListTableFilterComponent implements OnInit {
  @Input() type: string;
  @Input() selectOption: any;
  @Input() filterOption: any;
  @Output() filterChanged = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
  }

  onChangeSelect(e): void {
    console.log(e);
  }

  onChangeText(e): void {

  }
}
