import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAsUtc'
})
export class DateAsUtcPipe implements PipeTransform {

  transform(date: string|Date): any {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  }
}
