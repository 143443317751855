/* tslint:disable */
import {
  Article,
  Ad,
  Banner
} from '../index';

declare var Object: any;
export interface CategoryInterface {
  "kind": string;
  "name": string;
  "sequence"?: string;
  "id"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  articles?: Article[];
  ads?: Ad[];
  banners?: Banner[];
}

export class Category implements CategoryInterface {
  "kind": string;
  "name": string;
  "sequence": string;
  "id": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  articles: Article[];
  ads: Ad[];
  banners: Banner[];
  constructor(data?: Partial<CategoryInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Category`.
   */
  public static getModelName() {
    return "Category";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Category for dynamic purposes.
  **/
  public static factory(data: CategoryInterface): Category{
    return new Category(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Category',
      plural: 'Categories',
      path: 'Categories',
      idName: 'id',
      properties: {
        "kind": {
          name: 'kind',
          type: 'string'
        },
        "name": {
          name: 'name',
          type: 'string'
        },
        "sequence": {
          name: 'sequence',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
      },
      relations: {
        articles: {
          name: 'articles',
          type: 'Article[]',
          model: 'Article',
          relationType: 'hasMany',
          modelThrough: 'CategoryArticle',
          keyThrough: 'articleId',
          keyFrom: 'id',
          keyTo: 'categoryId'
        },
        ads: {
          name: 'ads',
          type: 'Ad[]',
          model: 'Ad',
          relationType: 'hasMany',
          modelThrough: 'CategoryAd',
          keyThrough: 'adId',
          keyFrom: 'id',
          keyTo: 'categoryId'
        },
        banners: {
          name: 'banners',
          type: 'Banner[]',
          model: 'Banner',
          relationType: 'hasMany',
          modelThrough: 'CategoryBanner',
          keyThrough: 'bannerId',
          keyFrom: 'id',
          keyTo: 'categoryId'
        },
      }
    }
  }
}
