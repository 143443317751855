import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { FileInfoApi, FileInfo, LoopBackFilter } from 'loopback';

@Component({
  templateUrl: 'media.page.html',
  styleUrls: ['media.page.scss']
})
export class MediaPage {

  files: FileInfo[] = [];
  selectedFile: FileInfo;
  filesPerPage: number = 60;
  currentFilter: LoopBackFilter = {
    order: 'createdAt DESC',
    limit: this.filesPerPage,
    where: {}
  };

  constructor(
    public fileApi: FileInfoApi
  ) {
    this.fetchFiles(1).subscribe(
      res => this.files = res
    );
  }

  fetchFiles(page: number): Observable<FileInfo[]> {
    this.currentFilter.skip = this.filesPerPage * (page - 1);

    return this.fileApi.find(this.currentFilter);
  }

  deleteFile(file: FileInfo) {
    this.fileApi.deleteById(file.id).subscribe(
      res => this.fetchFiles(1)
    );
  }

  onInfinite(event: any) {
    this.fetchFiles(event.page).subscribe(
      res => {
        if (res.length) {
          this.files = this.files.concat(res);
          event.callback(true);
        } else {
          event.callback(false);
        }
      }
    );
  }

  onSearch(word: string) {
    if (word) {
      this.currentFilter.where.name = {like: `%${word}%`};
    } else {
      delete this.currentFilter.where.name;
    }

    this.fetchFiles(1).subscribe(res => this.files = res);
  }
}
