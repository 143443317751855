import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'collectionDiff',
  pure: false
})
export class CollectionDiffPipe implements PipeTransform {

  transform(collection: any[], comparison: any[]): any {
    return collection.filter(collectionItem => {
      return comparison.every(comparisonItem => {
        return comparisonItem.id !== collectionItem.id;
      });
    });
  }
}
