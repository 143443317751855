import { Component, Input, ElementRef, HostListener } from '@angular/core';
import { GlobalStateService } from 'common/services/globalState.service';
import { GlobalMenuItem } from 'common/interfaces/globalMenuItem.interface';
import { layoutSizes } from 'common/common.constants';

@Component({
  selector: 'pb-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.scss']
})
export class SidebarComponent {

  menuHeight: number;
  isMenuCollapsed: boolean = false;
  isMenuShouldCollapsed: boolean = false;

  @Input() menu: GlobalMenuItem[];

  constructor(
    private elementRef: ElementRef,
    private state: GlobalStateService
  ) {
    this.state.onChanged('menu.isCollapsed').subscribe((changes) => {
      this.isMenuCollapsed = changes.currentValue;
    });
  }

  ngOnInit(): void {
    if (this._shouldMenuCollapse()) {
      this.menuCollapse();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.updateSidebarHeight());
  }

  @HostListener('window:resize')
  onWindowResize(): void {

    const isMenuShouldCollapsed = this._shouldMenuCollapse();

    if (this.isMenuShouldCollapsed !== isMenuShouldCollapsed) {
      this.menuCollapseStateChange(isMenuShouldCollapsed);
    }
    this.isMenuShouldCollapsed = isMenuShouldCollapsed;
    this.updateSidebarHeight();
  }

  menuExpand(): void {
    this.menuCollapseStateChange(false);
  }

  menuCollapse(): void {
    this.menuCollapseStateChange(true);
  }

  menuCollapseStateChange(isCollapsed: boolean): void {
    this.isMenuCollapsed = isCollapsed;
    this.state.set('menu.isCollapsed', this.isMenuCollapsed);
  }

  updateSidebarHeight(): void {
    // TODO: get rid of magic 84 constant
    this.menuHeight = this.elementRef.nativeElement.childNodes[0].clientHeight - 84;
  }

  private _shouldMenuCollapse(): boolean {
    return window.innerWidth <= layoutSizes.resWidthCollapseSidebar;
  }
}
