import {
  Component, Inject, Type, ViewChild, ElementRef, ViewContainerRef,
  ComponentFactoryResolver
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { ChannelApi, Channel, FileInfo } from 'loopback';
import * as _ from 'lodash';
import { EnvironmentService } from 'common/services/environment.service';
import { DYNAMIC_CHANNEL_EDITORS } from 'common/tokens';
import { mergeMap } from 'rxjs/operators';

@Component({
  templateUrl: 'channelDetail.page.html',
  styleUrls: ['channelDetail.page.scss']
})
export class ChannelDetailPage {

  id: number;
  channelForm: FormGroup;
  image: FileInfo[] = [];

  @ViewChild('dynamicEditor') editorArea: ElementRef;

  constructor(
    public formBuilder: FormBuilder,
    public cfr: ComponentFactoryResolver,
    public vcr: ViewContainerRef,
    public route: ActivatedRoute,
    public router: Router,
    public channelApi: ChannelApi,
    public envService: EnvironmentService,
    @Inject(DYNAMIC_CHANNEL_EDITORS) public editors: {name: string; component: Type<any>}[]
  ) {
    this.channelForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      slug: ['', Validators.required],
      publicChannel: [false],
      forceMute: [false],
      externalUrl: [''],
      channelType: ['']
    });
  }

  ngOnInit() {
    this.route.params.pipe(
      mergeMap(params => {
        if (params['id']) {
          const id = this.id = params['id'];
          this.channelForm.controls['id'].setValue(id);
          return this.channelApi.findById(id, {include: 'image'});
        } else {
          return of(new Channel());
        }
      })
    ).subscribe(channel => {
      this.setFormValue(channel);
      // this.setEditor(this.envService.isEnvGroup('yokotv') ? 'tvProgramAdvance' : 'tvProgramBasic');
      this.setEditor('tvProgramAdvance');
    });
  }

  setEditor(name: string) {
    const found = this.editors.find(e => e && e.name === name);

    if (!found) return;

    const component = found.component;
    const componentFactory = this.cfr.resolveComponentFactory(component);
    const componentRef = this.vcr.createComponent(componentFactory);

    componentRef.instance['channelId'] = this.id;
    componentRef.instance['channelName'] = this.channelForm.value.name;
    this.editorArea.nativeElement.appendChild(componentRef.location.nativeElement);
  }

  setFormValue(channel: Channel) {
    for (const prop in channel) {
      if (!channel.hasOwnProperty(prop)) continue;

      const control: AbstractControl = this.channelForm.controls[prop];

      if (control) {
        control.setValue(channel[prop]);
      }
    }

    if (channel.image) {
      this.image = [channel.image];
    }
  }

  upsertChannel() {
    const values = _.clone(this.channelForm.value);

    if (values.hasOwnProperty('id') && !values.id) {
      delete values.id;
    }

    values.imageId = this.image.length ? this.image[0].id : null;

    this.channelApi.upsert(values).subscribe(
      res => {
        this.router.navigate(['channel', res.id]);
      }
    );
  }
}
