import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NewsApi } from 'loopback';
import { DialogComponent } from 'common/components/dialog/dialog.component';
import { switchMapTo } from 'rxjs/operators';

@Component({
  selector: 'pb-notification-sender',
  templateUrl: 'notificationSender.component.html',
  styleUrls: ['notificationSender.component.scss']
})
export class NotificationSenderComponent {

  notificationForm: FormGroup;

  @ViewChild('confirm') confirm: DialogComponent;

  constructor(
    public formBuilder: FormBuilder,
    public newsApi: NewsApi
  ) {
    this.notificationForm = formBuilder.group({
      title: ['', Validators.required],
      content: ['', Validators.required]
    });
  }

  send() {
    const values = this.notificationForm.value;

    this.confirm.show().pipe(
      switchMapTo(this.newsApi.sendNotification({
        to: '/topics/breaking_news',
        notification: {
          title: values.title,
          body: values.content
        }
      }))
    ).subscribe(
      res => {
        console.log(res);
      }
    );
  }
}
