import { Component, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ToastType } from 'common/typeAliases';
import { environment } from 'env/env';
import { LoopBackConfig } from 'loopback';
import { menuConfig } from './menu';
import { ToastComponent } from 'common/components/toast/toast.component';
import { GlobalEventService } from 'common/services/globalEvent.service';
import { GlobalStateService } from 'common/services/globalState.service';
import { ThemePreloaderService } from 'common/services/themePreloader.service';
import { AppLoadingSpinnerService } from 'common/services/appLoadingSpinner.service';

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  isMenuCollapsed: boolean = false;
  isFullscreen: boolean = false;
  menu = menuConfig;
  logoImage = require('../assets/img/emark.svg');

  @ViewChild('globalToast') globalToast: ToastComponent;
  toastType: ToastType;
  toastMessage: string;

  constructor(
    private router: Router,
    private titleService: Title,
    private globalEvent: GlobalEventService,
    private state: GlobalStateService,
    private preloader: ThemePreloaderService,
    private spinner: AppLoadingSpinnerService
  ) {
    LoopBackConfig.setBaseURL(environment.apiUrl); // Loopback API base URL
    LoopBackConfig.filterOnUrl();
    LoopBackConfig.whereOnUrl();

    this.titleService.setTitle(environment.title); // document title

    this.state.onChanged('menu.isCollapsed').subscribe(changes => {
      setTimeout(() => this.isMenuCollapsed = changes.currentValue);
    });

    this.globalEvent.on('showGlobalToast').subscribe(param => {
      this.toastType = param.type;
      this.toastMessage = param.message;
      this.globalToast.show();
    });

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.isFullscreen = (e.urlAfterRedirects === '/login' || e.urlAfterRedirects === '/tv/preview');
      }
    });
  }

  ngAfterViewInit() {
    // hide spinner once all loaders are completed
    this.preloader.load().then((values) => {
      this.spinner.hide();
    });
  }
}
