import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { forkJoin } from 'rxjs';
import { TimeTable, Category } from 'loopback';
import { ChannelHelperService } from '../../services/channelHelper.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'pb-channel-contents-list',
  templateUrl: 'channelContentsList.component.html',
  styleUrls: ['channelContentsList.component.scss']
})
export class ChannelContentsListComponent {

  totalDuration: number = 0;
  categoryItems: {[id: number]: any} = {};
  categoryDurations: {[id: number]: number} = {};
  categoryFileSizes: {[id: number]: number} = {};
  programDurations: {[id: number]: number} = {};
  programFileSizes: {[id: number]: number} = {};

  printStyle: string = require('./print.css');

  @Input() programs: TimeTable[];
  @Input() date: string;
  @Input() channelName: string;
  @Input() showDate: boolean = true;
  @ViewChild('listBody') listBody: ElementRef;
  @ViewChild('printFrame') printFrame: ElementRef;

  constructor(
    private helper: ChannelHelperService
  ) {}

  print() {
    const content = this.listBody.nativeElement.innerHTML;
    const frame = this.printFrame.nativeElement as HTMLIFrameElement;
    frame.contentDocument.body.innerHTML = content;
    frame.contentWindow.print();
  }

  ngOnInit() {
    this.programs.sort((a, b) => {
      return a.start > b.start ? 1 : ( a.start < b.start ? -1 : 0 );
    });

    const allCategories = _.chain(this.programs).map('categories').flatten().uniqBy('id').value() as Category[];
    const fetchers = allCategories.map(category => {
      return this.helper.getCategoryContentsOfDay(category, this.date).pipe(
        tap(res => {
          this.categoryItems[category.id] = res;
          this.categoryDurations[category.id] = this.helper.getContentsTotalDuration(res);
          this.categoryFileSizes[category.id] = this.helper.getContentsTotalFileSize(res);
        })
      );
    });

    forkJoin(fetchers).subscribe(res => {
      this.programs.forEach(program => {
        if (program.embed) {
          this.programDurations[program.id] = moment(program.end).diff(program.start, 'seconds');
          this.programFileSizes[program.id] = 0;
        } else if (program.categories && program.categories.length) {
          const durations = program.categories.map(category => this.categoryDurations[category.id]);
          const fileSizes = program.categories.map(category => this.categoryFileSizes[category.id]);
          this.programDurations[program.id] = durations.reduce((prev, curr) => prev + curr);
          this.programFileSizes[program.id] = fileSizes.reduce((prev, curr) => prev + curr);
        } else {
          this.programDurations[program.id] = 0;
          this.programFileSizes[program.id] = 0;
        }
      });
    });
  }

  ngAfterViewInit() {
    this.initPrintFrame();
  }

  initPrintFrame() {
    const frame = this.printFrame.nativeElement as HTMLIFrameElement;
    const styleText = document.createTextNode(this.printStyle);
    const styleElement = document.createElement('style');

    styleElement.appendChild(styleText);
    frame.contentDocument.head.appendChild(styleElement);
  }
}
