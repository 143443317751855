import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import * as jQuery from 'jquery';

import { MenuService } from './menu.service';

import { GlobalMenuItem } from '../../interfaces/globalMenuItem.interface';
import { GlobalStateService } from '../../services/globalState.service';

@Component({
  selector: 'pb-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.scss'],
  providers: [MenuService]
})
export class MenuComponent {

  @Input() menuConfig: GlobalMenuItem[] = [];
  @Input() sidebarCollapsed: boolean = false;
  @Input() menuHeight: number;

  @Output() expandMenu = new EventEmitter<any>();

  menuItems: GlobalMenuItem[];
  showHoverElem: boolean;
  hoverElemHeight: number;
  hoverElemTop: number;
  onRouteChange: Subscription;
  outOfArea: number = -200;

  constructor(
    private router: Router,
    private service: MenuService,
    private state: GlobalStateService
  ) {
    this.onRouteChange = this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd) {
        if (this.menuItems) {
          this.selectMenuAndNotify();
        } else {
          // on page load we have to wait as event is fired before menu elements are prepared
          setTimeout(() => this.selectMenuAndNotify());
        }
      }
    });
  }

  selectMenuAndNotify(): void {
    if (this.menuItems) {
      this.menuItems = this.service.selectMenuItem(this.menuItems);
      this.state.set('menu.activeLink', this.service.getCurrentItem());
    }
  }

  ngOnInit(): void {
    this.menuItems = this.service.createMenu(this.menuConfig);
  }

  ngOnDestroy(): void {
    this.onRouteChange.unsubscribe();
  }

  hoverItem($event: MouseEvent): void {
    const target = $event.currentTarget as HTMLElement;

    this.showHoverElem = true;
    this.hoverElemHeight = target.clientHeight;
    this.hoverElemTop = target.getBoundingClientRect().top - 82;
  }

  toggleSubMenu($event: MouseEvent): boolean {
    const submenu = jQuery($event.currentTarget).next();
    const item: GlobalMenuItem = $event['item'];

    if (this.sidebarCollapsed) {
      this.expandMenu.emit(undefined);
      if (!item.expanded) {
        item.expanded = true;
      }
    } else {
      item.expanded = !item.expanded;
      submenu.slideToggle();
    }

    return false;
  }
}
