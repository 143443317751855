import { Component, Input, Output, HostBinding, HostListener, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'pb-list-table-sort',
  templateUrl: 'listTableSort.component.html',
  styleUrls: ['listTableSort.component.scss']
})
export class ListTableSortComponent implements OnInit {
  @Input() column: IPbListTableColumn;
  @Input() sortOption: any;
  @Output() sortChanged = new EventEmitter();

  @HostBinding('class.active') get classActive() {
    return this.isActive;
  }

  @HostBinding('class.asc') get classAsc() {
    return this.isActive && this.sortOption.order === 'asc';
  }

  @HostBinding('class.desc') get classDesc() {
    return this.isActive && this.sortOption.order === 'desc';
  }

  @HostListener('click') toggleSort(): void {
    let newOrder = 'asc';

    if (this.isActive) {
      newOrder = this.sortOption.order === 'asc' ? 'desc' : 'asc';
    }

    this.execSort(newOrder);
  }

  constructor() {}

  get isActive() {
    return this.column.name === this.sortOption.column;
  }

  ngOnInit(): void {
    if (this.isActive) {
      this.execSort(this.sortOption.order);
    }
  }

  execSort(order: string): void {
    const column = this.column;

    order = order || 'asc';

    this.sortOption.column = column.name;
    this.sortOption.order = order;
    this.sortChanged.emit({ column, order });
  }
}

export interface IPbListTableColumn {
  name: string;
  title: string;
  sort?: (r1: any, r2: any) => number;
  sortProperty?: 'string';
}

export interface IPbListTableConfig {
  sort: boolean;
}
