import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {

  transform(bytes: number, unit?: string): string {
    if (bytes < 1024) {
      return bytes + ' B';
    }

    const kb = bytes / 1024;
    if (kb < 1024) {
      return Math.ceil(kb) + ' KB';
    }

    const mb = kb / 1024;
    if (mb < 1024) {
      return Math.ceil(mb) + ' MB';
    }

    const gb = mb / 1024;
    return Math.ceil(gb) + 'GB';
  }
}
