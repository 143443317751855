import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { PbUserApi, PbUser } from 'loopback';
import { GlobalStateService } from '../../services/globalState.service';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'pb-page-top',
  templateUrl: 'pageTop.component.html',
  styleUrls: ['pageTop.component.scss']
})
export class PageTopComponent {

  isMenuCollapsed: boolean = false;
  userName: string = '';

  @Input() logoImage: string;

  constructor(
    private router: Router,
    private userApi: PbUserApi,
    private state: GlobalStateService,
    private role: RoleService
  ) {
    this.state.onChanged('menu.isCollapsed').subscribe((changes) => {
      this.isMenuCollapsed = changes.currentValue;
    });

    const userData: PbUser = this.userApi.getCachedCurrent();

    if (userData) {
      this.userName = userData.name;
    }
  }

  toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this.state.set('menu.isCollapsed', this.isMenuCollapsed);
  }

  logout() {
    this.userApi.logout().subscribe(
      res => {
        this.role.clear();
        this.router.navigateByUrl('/login');
      },
      res => console.error(res)
    );
  }
}
