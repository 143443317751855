import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'pb-modal',
  exportAs: 'pb-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss']
})
export class ModalComponent {

  @Input() config: any;
  @Input() title: string = '';
  @Input() type: string;
  @Input() size: 'sm'|'md'|'lg' = 'md';
  @Input() description: string = '';
  @Output() onShow: EventEmitter<any> = new EventEmitter();
  @Output() onShown: EventEmitter<any> = new EventEmitter();
  @Output() onHide: EventEmitter<any> = new EventEmitter();
  @Output() onHidden: EventEmitter<any> = new EventEmitter();
  @ViewChild('modal') modal: ModalDirective;

  get modalClass() {
    return this.type;
  }

  get sizeClass() {
    return this.size !== 'md' ? `modal-${this.size}` : '';
  }

  show() { this.modal.show(); }
  hide() { this.modal.hide(); }
  toggle() { this.modal.toggle(); }
}
