import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalMenuItem } from 'common/interfaces/globalMenuItem.interface';

@Component({
  selector: 'pb-menu-item',
  templateUrl: 'menuItem.component.html',
  styleUrls: ['menuItem.component.scss']
})
export class MenuItemComponent {

  @Input() menuItem: GlobalMenuItem;
  @Input() child: boolean = false;

  @Output() itemHover = new EventEmitter<any>();
  @Output() toggleSubMenu = new EventEmitter<any>();

  constructor(public router: Router) {}

  onHoverItem($event: MouseEvent): void {
    this.itemHover.emit($event);
  }

  onToggleSubMenu($event: MouseEvent, item: GlobalMenuItem): boolean {
    $event['item'] = item;
    this.toggleSubMenu.emit($event);

    return false;
  }

  onItemClick($event: MouseEvent, item: GlobalMenuItem): boolean {
    if (item.url) {
      window.open(item.url, item.target);
    } else {
      this.router.navigate(item.paths);
    }

    return false;
  }
}
