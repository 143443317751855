import { Pipe, PipeTransform } from '@angular/core';
import { FileInfo } from 'loopback';
import { FileInfoService } from '../services/fileInfo.service';

@Pipe({
  name: 'fileUrl'
})
export class FileUrlPipe implements PipeTransform {

  constructor(private service: FileInfoService) {}

  transform(fileObj: FileInfo, size: string = 'full'): string {
    return this.service.getUrl(fileObj, size);
  }
}
