import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(seconds: number = 0): string {
    let isMinus = false;

    seconds = Math.floor(seconds);

    if (seconds < 0) {
      isMinus = true;
      seconds = -seconds;
    }

    const s: number = seconds % 60;
    const minutes: number = (seconds - s) / 60;
    const m: number = minutes % 60;
    const h: number = (minutes - m) / 60;

    return `${h}時間${('0' + m).slice(-2)}分${('0' + s).slice(-2)}秒`;
  }
}
