import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BannerApi, CategoryApi, Banner, FileInfo } from 'loopback';
import { ArticleDetailComponentBase } from 'common/classes/articleDetailComponentBase.class';
import { GlobalEventService } from 'common/services/globalEvent.service';

@Component({
  templateUrl: 'bannerDetail.page.html'
})
export class BannerDetailPage extends ArticleDetailComponentBase<Banner> {

  image: FileInfo[] = [];

  constructor(
    public formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public globalEvent: GlobalEventService,
    public bannerApi: BannerApi,
    public categoryApi: CategoryApi
  ) {
    super();
    this.init();
  }

  fetchItem(id: number) {
    this.bannerApi.findOne({
      where: {id},
      include: ['categories', 'image']
    }).subscribe(
      res => this.setFormValue(res),
      err => console.error(err)
    );
  }

  fetchCategories() {
    this.categoryApi.find({where: {kind: 'banner'}}).subscribe(
      res => this.allCategories = res,
      err => console.error(err)
    );
  }

  createNewModel(): Banner {
    return new Banner();
  }

  setFormValue(item: Banner) {
    super.setFormValue(item);

    if (item.image) {
      this.image = [item.image];
    }
  }

  preSubmit(values: any) {
    values.imageId = this.image.length ? this.image[0].id : null;
  }

  upsert(values: any): Observable<any> {
    return this.bannerApi.upsert(values);
  }

  deleteCategories(id: number): Observable<any> {
    return this.bannerApi.deleteCategories(id);
  }

  linkCategory(itemId: number, categoryId: number): Observable<any> {
    return this.bannerApi.linkCategories(itemId, categoryId);
  }

  getAfterSubmitRoute(id: number): any[] {
    return ['tv', 'banner', id];
  }
}
