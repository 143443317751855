import { Component, ViewChild } from '@angular/core';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { LoopBackFilter, NewsApi, News } from 'loopback';
import * as _ from 'lodash';
import { ListComponentBase } from 'common/classes/listComponentBase.class';
import { ListTableColumn } from 'common/interfaces/listTableColumn.interface';
import { ListTableSortOption } from 'common/interfaces/listTableSortOption.interface';
import { DialogComponent } from 'common/components/dialog/dialog.component';
import { debounceTime } from 'rxjs/operators';
import { GlobalStateService } from 'common/services/globalState.service';

@Component({
  templateUrl: 'newsList.page.html'
})
export class NewsListPage extends ListComponentBase {

  columns: ListTableColumn[] = [
    {
      name: 'type',
      title: 'Type',
      sortProperty: 'type'
    },
    {
      name: 'content',
      title: 'Content',
      sortProperty: 'title'
    },
    {
      name: 'createdAt',
      title: 'Date created',
      sortProperty: 'createdAt'
    }
  ];

  sortOption: ListTableSortOption = {
    column: 'createdAt',
    order: 'desc'
  };

  searchWord: AbstractControl;

  list: News[];

  currentFilter: LoopBackFilter = {
    where: {},
    include: ['image']
  };

  typeLabels = {
    text: 'Text',
    image: 'Image',
    embed: 'Embed'
  };

  levelLabels = {
    low: 'Low',
    high: 'High'
  };

  @ViewChild('dialog') dialog: DialogComponent;

  constructor(
    public formBuilder: FormBuilder,
    public newsApi: NewsApi,
    private state: GlobalStateService
  ) {
    super();
  }

  ngOnInit() {
    this.searchWord = this.formBuilder.control('');
    this.searchWord.valueChanges.pipe(debounceTime(500)).subscribe(
      value => this.search(value)
    );

    this.state.set('menu.activeLink', { title: 'Urgent Info History' });

    this.init();
  }

  fetchListData(page: number, requireCount: boolean = false): Observable<News[]> {
    let source: Observable<News[]>;
    const filter: LoopBackFilter = this.getPagingFilter(_.clone(this.currentFilter), page);
    let countSource: Observable<{count: number}>;

    source = this.newsApi.find(filter);
    countSource = this.newsApi.count(filter.where);

    return this.fetch(source, countSource, requireCount);
  }

  search(word: string) {
    if (word) {
      this.currentFilter.where.or = [{name: {like: `%${word}%`}}];
    } else {
      delete this.currentFilter.where.or;
    }

    this.fetchListData(1, true).subscribe();
  }

  deleteSelected() {
    this.dialog.show().subscribe(
      result => {
        if (result) {
          super.deleteSelected();
        }
      }
    );
  }

  deleteById(id: number): Observable<any> {
    return this.newsApi.deleteById(id);
  }
}
