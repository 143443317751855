/*
 * bootstraping
 */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

/**
 * locales
 */
import { registerLocaleData } from '@angular/common';
import localeJa from '@angular/common/locales/ja';

/*
 * App Module
 */
import { AppModule } from 'app/app.module';
import { environment } from 'env/env';

/**
 * Dependencies
 */
import 'jquery';
import 'lodash';
import 'moment';
import 'moment/locale/ja';

window['__theme'] = 'bs4';

registerLocaleData(localeJa);

if (environment.production) {
  enableProdMode();
}

/*
 * bootstrap with AppModule
 */
platformBrowserDynamic().bootstrapModule(AppModule).then(modRef => {
  const hot = module['hot'];
  const modInstance = modRef.instance;

  if (hot) {
    hot.accept();

    if (hot.data) {
      modInstance.hmrOnInit(hot.data);
    }

    hot.addDisposeHandler((store) => {
      modInstance.hmrOnDestroy(store);
      modRef.destroy();
      modInstance.hmrAfterDestroy(store);
    });
  }

  return modRef;
});
