import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalEventService } from './globalEvent.service';

@Injectable()
export class GlobalStateService {
  private states: {[name: string]: any } = {};

  constructor(private globalEvent: GlobalEventService) {}

  onChanged(name: string): Observable<any> {
    return this.globalEvent.on(`stateChanged:${name}`);
  }

  get(name?: string): any {
    return name === undefined ? this.states : this.states[name];
  }

  set(name: string, value: any) {
    const previousValue = this.states[name];

    if (previousValue !== value) {
      this.states[name] = value;
      this.globalEvent.trigger(`stateChanged:${name}`, {previousValue, currentValue: value});
    }
  }
}
