import { Component, Input, Output, ViewChild, EventEmitter, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { FileInfoApi, FileInfo, LoopBackFilter } from 'loopback';
import * as _ from 'lodash';
import { MediaSelectorOptions } from '../../interfaces/mediaSelectorOptions.interface';
import { ScrollContentComponent } from '../scrollContent/scrollContent.component';
import { ModalComponent } from '../modal/modal.component';
import { DialogComponent } from '../dialog/dialog.component';
import { debounceTime, switchMapTo } from 'rxjs/operators';

@Component({
  selector: 'pb-media-selector',
  templateUrl: 'mediaSelector.component.html',
  styleUrls: ['mediaSelector.component.scss']
})
export class MediaSelectorComponent {

  selectedFileIndex: number;
  selectedFile: FileInfo;

  dragging: boolean = false;
  sorting: boolean = false;
  movingFileIndex: number;
  moveoverFileIndex: number;

  filesForSelector: FileInfo[] = [];
  selectorViewSelection: FileInfo;
  selectorFilesPerPage: number = 60;
  selectorFetchFilter: LoopBackFilter = {
    order: 'createdAt DESC',
    limit: this.selectorFilesPerPage,
    where: {}
  };

  optionsDefaults: MediaSelectorOptions = {
    thumbnailSize: 'normal',
    maxFiles: undefined,
    selectorFileType: undefined
  };

  searchWord: FormControl;
  remoteIsEmpty: boolean = false;

  @Input() files: FileInfo[] = [];
  @Input() mode: 'selection'|'selector'|'master' = 'selection';
  @Input() options: MediaSelectorOptions = {};
  @Output() fileSelect: EventEmitter<FileInfo> = new EventEmitter<FileInfo>();
  @Output() fileDeselect: EventEmitter<FileInfo> = new EventEmitter<FileInfo>();
  @Output() infinite: EventEmitter<any> = new EventEmitter<any>();
  @Output() search: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('scroller') scroller: ScrollContentComponent;
  @ViewChild('selectorView') selectorView: ModalComponent;
  @ViewChild('deletionConfirm') deletionConfirm: DialogComponent;

  constructor(
    private fileApi: FileInfoApi
  ) {
    this.searchWord = new FormControl('');
    this.searchWord.valueChanges.pipe(debounceTime(500)).subscribe(
      value => {
        if (this.scroller) {
          this.scroller.resetInfiniteState();
        }
        this.deselectFile();
        this.search.emit(value);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['files']) {
      this.checkEmpty();
    }
  }

  ngOnInit() {
    _.defaults(this.options, this.optionsDefaults);

    if (this.options.selectorFileType) {
      this.selectorFetchFilter.where.type = this.options.selectorFileType;
    }
  }

  enableDropArea() {
    if (this.sorting) return;

    this.dragging = true;
  }

  removeFile(index: number) {
    this.files.splice(index, 1);
    this.checkEmpty();
  }

  getFilesForSelector(page: number): Observable<FileInfo[]> {
    this.selectorFetchFilter.skip = this.selectorFilesPerPage * (page - 1);

    return this.fileApi.find(this.selectorFetchFilter);
  }

  showSelectorView() {
    this.getFilesForSelector(1).subscribe(
      res => {
        this.filesForSelector = res;
        this.selectorView.show();
      }
    );
  }

  hideSelectorView() {
    this.selectorView.hide();
  }

  onSelectorInfinite(event: any) {
    this.getFilesForSelector(event.page).subscribe(
      res => {
        if (res.length) {
          this.filesForSelector = this.filesForSelector.concat(res);
          event.callback(true);
        } else {
          event.callback(false);
        }
      }
    );
  }

  selectFile(index: number) {
    const file = this.files[index];

    this.selectedFileIndex = index;
    this.selectedFile = file;
    this.fileSelect.emit(file);
  }

  deselectFile() {
    this.fileDeselect.emit(this.selectedFile);
    this.selectedFileIndex = undefined;
    this.selectedFile = undefined;
  }

  pushFile(file: FileInfo) {
    this.files.push(file);
    this.checkEmpty();
    this.hideSelectorView();
  }

  deleteFile(index: number) {
    const file = this.files[index];

    this.deletionConfirm.show().pipe(switchMapTo(this.fileApi.deleteById(file.id))).subscribe(
      res => {
        this.removeFile(index);
        this.deselectFile();
      }
    );
  }

  checkEmpty() {
    this.remoteIsEmpty = (!this.files || !this.files.length) && this.searchWord.value === '';
  }

  onSelectorSearch(word: string) {
    if (word) {
      this.selectorFetchFilter.where.name = {like: `%${word}%`};
    } else {
      delete this.selectorFetchFilter.where.name;
    }

    this.getFilesForSelector(1).subscribe(res => this.filesForSelector = res);
  }

  onDragover(e: DragEvent) {
    e.preventDefault();
  }

  onDrop(e: DragEvent) {
    e.preventDefault();
    this.dragging = false;

    if (this.files.length === this.options.maxFiles) {
      return;
    }

    const files = e.dataTransfer.files;

    Array.prototype.forEach.call(files, (file: File) => {
      const formData = new FormData();
      const type: string[] = file.type.split('/');

      if (this.options.selectorFileType && this.options.selectorFileType !== type[0]) {
        return;
      }

      formData.append('file', file);
      this.fileApi.upload(formData).subscribe(
        res => {
          this.files.unshift(res.result);
          this.checkEmpty();
        }
      );
    });
  }

  moveStart(e: DragEvent, file: FileInfo, index: number) {
    e.stopPropagation();
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.dropEffect = 'move';

    this.movingFileIndex = index;
    this.sorting = true;
  }

  moveEnd(e: DragEvent, file: FileInfo) {
    e.stopPropagation();

    this.movingFileIndex = undefined;
    this.moveoverFileIndex = undefined;
    this.sorting = false;
  }

  moveEnter(e: DragEvent, file: FileInfo, index: number) {
    e.stopPropagation();
    e.preventDefault();
    this.moveoverFileIndex = index;
  }

  moveOver(e: DragEvent, file: FileInfo) {
    e.stopPropagation();
    e.preventDefault();
  }

  moveLeave(e: DragEvent, file: FileInfo) {
    e.stopPropagation();
    e.preventDefault();
  }

  moveDrop(e: DragEvent, file: FileInfo, index: number) {
    e.stopPropagation();
    let movingFile: FileInfo;
    const spliced: FileInfo[] = this.files.splice(this.movingFileIndex, 1);

    if (!spliced.length) return;

    if (this.movingFileIndex < index) index--;

    movingFile = spliced[0];
    this.files.splice(index, 0, movingFile);
  }

  throughInfinite(event: any) {
    this.infinite.emit(event);
  }
}
