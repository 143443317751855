import { NgModule, Inject, LOCALE_ID } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';

export const translationMap = {
  ja: require('./ja.json')
};

export class TranslateObjectLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    return of(translationMap[lang]);
  }
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useClass: TranslateObjectLoader }
    })
  ],
  exports: [
    TranslateModule
  ]
})
export class I18nModule {
  constructor(
    public translateService: TranslateService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    // TODO: 言語切り替え機能
    this.translateService.setDefaultLang('en');
    this.translateService.use(locale);
  }
}
