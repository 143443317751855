import { ElementRef, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Observable, Subscription, fromEvent, of } from 'rxjs';
import { ChannelApi, TimeTableApi, TimeTable, CategoryApi, Category, LoopBackFilter } from 'loopback';
import { EventObjectInput } from 'fullcalendar';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ChannelCategoryMeta } from 'common/interfaces/channelCategoryMeta.interface';
import { ModalComponent } from 'common/components/modal/modal.component';
import { FullCalendarComponent } from 'common/components/fullCalendar/fullCalendar.component';
import { DialogComponent } from 'common/components/dialog/dialog.component';
import { ChannelHelperService } from 'common/services/channelHelper.service';
import { map, tap, mergeMap, throttle, take } from 'rxjs/operators';

export abstract class TvProgramEditorBase {

  channelId: number;
  channelName: string;

  programs: {[id: number]: FormGroup} = {};

  calendarTabs = [
    {type: 'main', heading: 'Main Area'},
    {type: 'banner', heading: 'Banner Area'}
  ];

  allCategories: Category[];
  categoriesGroup: {[type: string]: ChannelCategoryMeta[]} = {
    main: [
      {
        kind: 'article',
        name: 'Article Categories',
        categories: []
      },
      {
        kind: 'ad',
        name: 'Ad Categories',
        categories: []
      }
    ],
    banner: [
      {
        kind: 'banner',
        name: 'Banner Categories',
        categories: []
      }
    ]
  };
  availableCategoriesGroup: ChannelCategoryMeta[];

  editingProgram: FormGroup;
  editingCalendarEvent: EventObjectInput;
  listContents: TimeTable[];
  dayMenuParam: {date: moment.Moment; calendar: string};
  contentsListTargetDate: moment.Moment;

  dropdownPosition: any = { top: '0', left: '0' };

  shiftPressed: boolean = false;
  copyMode: boolean = false;
  copyTarget: {
    program?: FormGroup
    element?: any
  };
  copyTargetDate: moment.Moment;
  copyTargetDatePrograms: FormGroup[];

  programEditorModal: ModalComponent;
  contentsListModal: ModalComponent;
  calendars: QueryList<FullCalendarComponent>;
  bannerCalendar: FullCalendarComponent;
  programDeletionConfirm: DialogComponent;
  wholeDayDeletionConfirm: DialogComponent;
  dayMenuToggle: ElementRef;

  editingProgramTmpValue: any;
  keyEventSubscription: Subscription;
  viewInitialized: boolean = false;
  selectedTab = 'main';

  formBuilder: FormBuilder;
  route: ActivatedRoute;
  router: Router;
  channelApi: ChannelApi;
  timeTableApi: TimeTableApi;
  categoryApi: CategoryApi;
  helper: ChannelHelperService;
  locale: string;

  ngOnInit() {
    this.fetchCategories();
    this.keyEventSubscription = fromEvent<KeyboardEvent>(document, 'keydown').pipe(
      throttle(
        keydown => fromEvent<KeyboardEvent>(document, 'keyup').pipe(
          take(1),
          tap(keyup => {
            this.shiftPressed = false;
            this.copyMode = false;
          })
        )
      )
    ).subscribe(
      keydown => {
        if (keydown.shiftKey) {
          this.shiftPressed = true;
        }
      }
    );
  }

  ngOnDestroy() {
    this.keyEventSubscription.unsubscribe();
    this.helper.clearContentsCache();
  }

  ngAfterViewInit() {
    setTimeout(() => this.viewInitialized = true);
  }

  getCalendarConfig(name: string) {
    return {
      customButtons: {
        slotChange1: {
          text: '30分',
          click: () => {
            const calendar = this.getCalendar(name);
            calendar.setOption('slotDuration', '00:30:00');
            calendar.setOption('snapDuration', '00:10:00');
          }
        },
        slotChange2: {
          text: '10分',
          click: () => {
            const calendar = this.getCalendar(name);
            calendar.setOption('slotDuration', '00:10:00');
            calendar.setOption('snapDuration', '00:05:00');
          }
        },
        slotChange3: {
          text: '1分',
          click: () => {
            const calendar = this.getCalendar(name);
            calendar.setOption('slotDuration', '00:01:00');
            calendar.setOption('snapDuration', '00:00:15');
          }
        }
      },
      header: {
        left: 'title',
        right: 'slotChange1,slotChange2,slotChange3 today prev,next'
      },
      defaultView: 'agendaWeek',
      height: 'parent',
      allDaySlot: false,
      slotDuration: '00:01:00',
      slotLabelFormat: 'k:mm',
      timeFormat: 'H:mm(:ss)',
      snapDuration: '00:00:15',
      scrollTime: '08:00:00',
      selectable: true,
      selectOverlap: false,
      editable: true,
      navLinks: true,
      eventOverlap: false,
      eventBackgroundColor: '#ffeaee',
      eventBorderColor: '#f49fb1',
      eventConstraint: {start: '0:00', end: '24:00'},
      eventAllow: (dropLocation, event) => this.eventAllowed(dropLocation, event),
      now: moment().utc(true).toISOString(),
      timezone: 'UTC',
      locale: this.locale
    };
  }

  fetchCategories() {
    this.categoryApi.find().subscribe(
      res => {
        const grouped = _.groupBy(res, 'kind');

        _.each(this.categoriesGroup, (g, type) => {
          g.forEach(h => {
            h.categories = grouped[h.kind] || [];
          });
        });

        this.allCategories = res;
      }
    );
  }

  fetchPrograms(type: string, filter: LoopBackFilter): Observable<TimeTable[]> {
    filter.include = 'categories';

    const prop = type === 'banner' ? 'bannerTimeline' : 'contentTimeline';
    const channelFilter = {
      include: {
        relation: prop,
        scope: filter
      }
    };

    return this.channelApi.findById(this.channelId, channelFilter).pipe(
      map(res => res[prop].map(item => {
        const categories = [];
        if (item.categoryIds && item.categories) {
          item.categoryIds.forEach(id => {
            categories.push(item.categories.find(cat => id === cat.id));
          });
          item.categories = categories;
        }
        return item;
      })),
      tap(res => {
        res.forEach(timeline => this.programs[timeline.id] = this.createProgram(timeline));
      })
    );
  }

  onEventsFetch(param: any) {
    const type = param.name;
    const filter = {
      where: {
        start: {gte: param.start.toISOString()},
        end: {lte: param.end.toISOString()}
      }
    };

    this.fetchPrograms(type, filter).subscribe(
      res => {
        param.cb(res.map(item => this.helper.applyCalendarEventProp(item)));
      }
    );
  }

  onEventRender(param: any) {
    const program: FormGroup = this.programs[param.event.id];

    param.el.on('contextmenu', e => {
      e.preventDefault();
      this.setCopyTarget(program, param.el);
    });
  }

  getCalendar(name: string): FullCalendarComponent {
    const filtered = this.calendars.filter((calendar, index) => {
      return calendar.name === name;
    });

    return filtered.length ? filtered[0] : undefined;
  }

  renderCalendar(name: string) {
    setTimeout(() => {
      this.selectedTab = name;
    });
  }

  showMenu(name, e) {
    const toggle = this[name + 'Toggle'];
    toggle.nativeElement.click();
    this.dropdownPosition = { left: e.pageX + 'px', top: e.pageY + 'px' };
  }

  onToggleDayMenu(isOpen: boolean) {
    if (!isOpen) setTimeout(() => this.dayMenuParam = undefined);
  }

  createProgram(model): FormGroup {
    const categoryIds: FormArray = this.formBuilder.array([]);
    const categories: FormArray = this.formBuilder.array([]);
    const insertionCategoryIds: FormArray = this.formBuilder.array([]);
    const insertionCategories: FormArray = this.formBuilder.array([]);
    const form: FormGroup = this.formBuilder.group({
      id: [model.id],
      kind: [model.kind, Validators.required],
      start: [model.start],
      end: [model.end],
      embed: [model.embed],
      categoryIds,
      categories,
      insertionCategoryIds,
      insertionCategories,
      insertionInterval: [model.insertionInterval]
    });

    for (const id of model.categoryIds) {
      categoryIds.push(this.formBuilder.control(id));
    }

    if (model.insertionCategoryIds) for (const id of model.insertionCategoryIds) {
      insertionCategoryIds.push(this.formBuilder.control(id));
    }

    if (model.categories) for (const category of model.categories) {
      categories.push(this.formBuilder.control(category));
    }

    if (model.insertionCategories) for (const category of model.insertionCategories) {
      insertionCategories.push(this.formBuilder.control(category));
    }

    return form;
  }

  editProgram(program: FormGroup) {
    const kind = program.get('kind').value;

    this.editingProgram = program;
    this.availableCategoriesGroup = this.categoriesGroup[kind];
    this.editingProgramTmpValue = program.value;
    this.programEditorModal.show();
  }

  onEventClick(param: any) {
    this.editingCalendarEvent = param.event;
    this.editProgram(this.programs[param.event.id]);
  }

  onTimelineSelect(param: any) {
    if (this.copyTarget) return;

    const newModel = this.createProgram({
      id: 'new',
      start: param.start.toISOString(),
      end: param.end.toISOString(),
      categoryIds: [],
      insertionCategoryIds: [],
      embed: '',
      kind: param.name
    });

    this.editProgram(newModel);
  }

  onDayClick(param: any) {
    this.pasteProgram(param.date);
  }

  onEventDragStart(param: any) {
    if (this.shiftPressed) {
      this.copyMode = true;
    }
  }

  onEventDrop(param: any) {
    const program: FormGroup = this.programs[param.event.id];

    if (this.copyMode) {
      this.duplicateProgram(program, param.event.start, param.event.end).subscribe();
      param.revertFn();
    } else {
      this.updateProgramTime(program, param.event.start, param.event.end).subscribe();
    }
  }

  onEventResize(param: any) {
    const program: FormGroup = this.programs[param.event.id];

    this.updateProgramTime(program, param.event.start, param.event.end).subscribe();
  }

  eventAllowed(dropLocation: any, event: any): boolean {
    if (this.copyMode) {
      return dropLocation.start.isSameOrAfter(event.end) || dropLocation.end.isSameOrBefore(event.start);
    } else {
      return true;
    }
  }

  onNavLinkDayClick(param: any) {
    this.dayMenuParam = {date: param.date, calendar: param.name};
    param.jsEvent.stopPropagation();
    this.showMenu('dayMenu', param.jsEvent);
  }

  onHideProgramEditor() {
    this.availableCategoriesGroup = undefined;
    this.editingProgram = undefined;
    this.editingProgramTmpValue = undefined;
    this.editingCalendarEvent = undefined;
  }

  updateEditingProgram() {
    this.upsertProgram(this.editingProgram).subscribe(
      res => this.programEditorModal.hide()
    );
  }

  resetEditingProgram() {
    this.editingProgram.reset(this.editingProgramTmpValue);
    this.programEditorModal.hide();
  }

  deleteEditingProgram() {
    this.programDeletionConfirm.show().subscribe(
      result => {
        if (result) {
          this.deleteProgram(this.editingProgram).subscribe(
            res => this.programEditorModal.hide()
          );
        }
      }
    );
  }

  upsertProgram(program: FormGroup): Observable<FormGroup> {
    const values = _.clone(program.value);
    let isNew = false;

    if (values.id === 'new') {
      isNew = true;
      delete values.id;
    }

    return this.timeTableApi.upsert(values).pipe(
      map(res => {
        res.categories = res.categoryIds.map(id => {
          return this.allCategories.find(category => category.id === id);
        });
        return res;
      }),
      tap(res => {
        const calendar = this.getCalendar(res.kind);
        let eventData = isNew ? res : this.editingCalendarEvent;

        if (!isNew) {
          if (!eventData) return;

          eventData['categoryIds'] = res.categoryIds;
          eventData['embed'] = res.embed;
        }

        eventData['categories'] = res.categories;

        eventData = this.helper.applyCalendarEventProp(eventData);

        isNew ? calendar.renderEvent(eventData) : calendar.updateEvent(eventData);
      }),
      map(res => this.programs[res.id] = this.createProgram(res)),
      mergeMap(res => (isNew ? this.linkProgram(res) : of(undefined)).pipe(map(() => res)))
    );
  }

  deleteProgram(program: FormGroup): Observable<any> {
    const id = program.get('id').value;
    const kind = program.get('kind').value;

    return this.timeTableApi.deleteById(id).pipe(
      tap(res => {
        this.getCalendar(kind).removeEvents(id);
        delete this.programs[id];
      })
    );
  }

  duplicateProgram(program: FormGroup, start: moment.Moment, end: moment.Moment): Observable<FormGroup> {
    const duplicated = this.createProgram({
      id: 'new',
      start: start.toISOString(),
      end: end.toISOString(),
      kind: program.get('kind').value,
      categoryIds: program.get('categoryIds').value,
      insertionCategoryIds: program.get('insertionCategoryIds').value,
      insertionInterval: program.get('insertionInterval').value,
      embed: program.get('embed').value
    });

    return !this.checkDuplication(duplicated) ? this.upsertProgram(duplicated) : of(undefined);
  }

  linkProgram(program: FormGroup): Observable<any> {
    return program.get('kind').value === 'main'
      ? this.channelApi.linkContentTimeline(this.channelId, program.get('id').value)
      : this.channelApi.linkBannerTimeline(this.channelId, program.get('id').value);
  }

  updateProgramTime(program: FormGroup, start, end): Observable<any> {
    program.get('start').setValue(start.toISOString());
    program.get('end').setValue(end.toISOString());

    return this.upsertProgram(program);
  }

  setCopyTarget(program: FormGroup, element: any) {
    const isSameEvent = this.copyTarget && this.copyTarget.program === program;
    const type: string = program.get('kind').value;

    if (this.copyTarget) {
      this.clearCopyTarget();
    }

    if (!isSameEvent) {
      element.css('opacity', 0.6);
      this.copyTarget = {program, element};
    }
  }

  clearCopyTarget() {
    if (!this.copyTarget) return;

    this.copyTarget.element.css('opacity', '');
    this.copyTarget = undefined;
  }

  pasteProgram(start: moment.Moment) {
    if (!this.copyTarget) return;

    const program = this.copyTarget.program;
    const duration = moment(program.get('end').value).diff(program.get('start').value);
    const end = start.clone().add(duration);

    this.duplicateProgram(program, start, end).subscribe(
      res => this.clearCopyTarget()
    );
  }

  checkDuplication(program: FormGroup, haystack?: FormGroup[]) {
    const targetStart = moment.utc(program.get('start').value);
    const targetEnd = moment.utc(program.get('end').value);
    const targetKind = program.get('kind').value;
    const programs: FormGroup[] = haystack || _.values<FormGroup>(this.programs);

    const duplications = programs.filter(tt => {
      const start = moment.utc(tt.get('start').value);
      const end = moment.utc(tt.get('end').value);
      const kind = tt.get('kind').value;

      return start < targetEnd && end > targetStart && kind === targetKind;
    });

    return !!duplications.length;
  }

  onMenuCopy(event: MouseEvent) {
    this.setCopyTargetDate(this.dayMenuParam.date, this.dayMenuParam.calendar, event);
  }

  onMenuPaste(event: MouseEvent) {
    this.pasteWholeDay(this.dayMenuParam.date, this.dayMenuParam.calendar, event);
  }

  onMenuContentsList(event: MouseEvent) {
    this.showContentsList(this.dayMenuParam.date, this.dayMenuParam.calendar, event);
  }

  onMenuDelete(event: MouseEvent) {
    this.deleteWholeDay(this.dayMenuParam.date, this.dayMenuParam.calendar, event);
  }

  setCopyTargetDate(day: moment.Moment, calendarName: string, event?: MouseEvent) {
    if (event) event.preventDefault();

    this.copyTargetDate = day.clone();
    this.copyTargetDatePrograms = this.getTimelineOfDay(day, calendarName);
  }

  pasteWholeDay(day: moment.Moment, calendarName: string, event?: MouseEvent) {
    if (event) event.preventDefault();

    if (!this.copyTargetDate) return;

    const programs: FormGroup[] = this.copyTargetDatePrograms;
    const targetDay: moment.Moment = day.clone();
    const dateDiff: number = targetDay.diff(this.copyTargetDate, 'days');
    const operations: Observable<any>[] = [];
    const clones: FormGroup[] = [];
    const targetDayPrograms: FormGroup[] = this.getTimelineOfDay(targetDay, calendarName);
    const calendar = this.getCalendar(calendarName);

    programs.forEach(program => {
      const clone = this.createProgram({
        start: moment(program.get('start').value).add(dateDiff, 'days').toISOString(),
        end: moment(program.get('end').value).add(dateDiff, 'days').toISOString(),
        kind: program.get('kind').value,
        categoryIds: program.get('categoryIds').value,
        insertionCategoryIds: program.get('insertionCategoryIds').value,
        insertionInterval: program.get('insertionInterval').value,
        embed: program.get('embed').value
      });

      if (!this.checkDuplication(clone, targetDayPrograms)) clones.push(clone.value);
    });

    this.timeTableApi.createManyAndLinkChannel(clones, this.channelId).subscribe(
      res => {
        res.forEach(tt => this.programs[tt.id] = this.createProgram(tt));
        calendar.refetchEvents();
      }
    );
  }

  deleteWholeDay(day: moment.Moment, calendarName: string, event?: MouseEvent) {
    if (event) event.preventDefault();

    this.wholeDayDeletionConfirm.show().subscribe(
      result => {
        const programs: FormGroup[] = this.getTimelineOfDay(day, calendarName);
        const ids: number[] = programs.map(program => program.value.id);
        const calendar = this.getCalendar(calendarName);
        this.timeTableApi.deleteByIds(ids).subscribe(
          () => {
            programs.forEach(program => delete this.programs[program.value.id]);
            calendar.refetchEvents();
          }
        );
      }
    );
  }

  getTimelineOfDay(day: moment.Moment, calendarName: string): FormGroup[] {
    const endOfDay = day.clone().add(1, 'day');
    const programs: FormGroup[] = _.values<FormGroup>(this.programs);

    return programs.filter(tt => {
      const start = moment(tt.get('start').value);
      const end = moment(tt.get('end').value);
      const kind = tt.get('kind').value;

      return start >= day && end <= endOfDay && kind === calendarName;
    });
  }

  showContentsList(day: moment.Moment, calendarName: string, event: MouseEvent) {
    event.preventDefault();

    this.listContents = this.getTimelineOfDay(day, calendarName).map(tt => new TimeTable(tt.value));
    this.contentsListTargetDate = day;
    this.contentsListModal.show();
  }

  onHiddenContentsList() {
    this.listContents = undefined;
    this.contentsListTargetDate = undefined;
  }
}
