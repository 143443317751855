import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

export const routes: Routes = [
  {
    path: '**',
    redirectTo: '/login'
  }
];

export const appRouting: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });
