import { Injectable } from '@angular/core';
import { environment } from 'env/env';

@Injectable()
export class EnvironmentService {

  isEnvGroup(name: string) {
    return environment.group === name;
  }
}
