import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'lookupName'})
export class LookupNamePipe implements PipeTransform {

  transform(
    needle: string | number, haystack: any[],
    lookupKey: string = 'id', returnKey: string = 'name'
  ): string {
    const found = haystack.find(hay => hay[lookupKey] === needle);
    return found && found[returnKey] ? found[returnKey] : undefined;
  }
}
