import { Component } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientApi, Client, LoopBackFilter } from 'loopback';

@Component({
  templateUrl: 'clientDetail.page.html'
})
export class ClientDetailPage {

  public id: string|number;
  public form: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public clientApi: ClientApi
  ) {
    this.form = this.formBuilder.group({
      id: [''],
      name: [''],
      key: ['']
    });

    this.route.params.subscribe(params => {
      if (params['id']) {
        const id = this.id = params['id'];
        this.fetchClient({
          where: {id}
        });
        this.form.controls['id'].setValue(id);
      } else {
        this.setFormValue(new Client());
      }
    });
  }

  fetchClient(filter: LoopBackFilter) {
    this.clientApi.findOne(filter).subscribe(
      res => this.setFormValue(res),
      err => console.error(err)
    );
  }

  setFormValue(client: Client) {
    for (const prop in client) {
      if (!client.hasOwnProperty(prop)) continue;

      const control: AbstractControl = this.form.controls[prop];

      if (control) {
        control.setValue(client[prop]);
      }
    }
  }
}
