import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CategoryApi } from 'loopback';
import { CategoryListComponentBase } from 'common/classes/categoryListComponentBase.class';
import { DialogComponent } from 'common/components/dialog/dialog.component';

@Component({
  templateUrl: 'articleCategory.page.html'
})
export class ArticleCategoryPage extends CategoryListComponentBase {

  kind: string = 'article';

  @ViewChild('dialog') dialog: DialogComponent;

  constructor(
    public formBuilder: FormBuilder,
    public categoryApi: CategoryApi
  ) {
    super();

    this.init();
  }
}
