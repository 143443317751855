import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ChannelCategoryMeta } from '../../interfaces/channelCategoryMeta.interface';

@Component({
  selector: 'pb-program-editor',
  templateUrl: 'programEditor.component.html',
  styleUrls: ['programEditor.component.scss']
})
export class ProgramEditorComponent {

  @Input() program: FormGroup;
  @Input() categoryGroup: ChannelCategoryMeta[];
  @Input() showDate: boolean = true;

}
