import { Component, ViewChild } from '@angular/core';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ArticleApi, CategoryApi, Article, Category, LoopBackFilter } from 'loopback';
import * as _ from 'lodash';
import { ListComponentBase } from 'common/classes/listComponentBase.class';
import { ListTableColumn } from 'common/interfaces/listTableColumn.interface';
import { ListTableSortOption } from 'common/interfaces/listTableSortOption.interface';
import { DialogComponent } from 'common/components/dialog/dialog.component';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  templateUrl: 'articleList.page.html'
})
export class ArticleListPage extends ListComponentBase {

  columns: ListTableColumn[] = [
    {
      name: 'title',
      title: 'Title',
      sortProperty: 'title'
    },
    {
      name: 'categories',
      title: 'Categories',
      sortProperty: 'categories'
    },
    {
      name: 'publicationPeriod',
      title: 'Publication period',
      sortProperty: ['startDate', 'endDate']
    },
    {
      name: 'displayTime',
      title: 'Display time',
      sortProperty: 'displayTime'
    },
    {
      name: 'createdAt',
      title: 'Date created',
      sortProperty: 'createdAt'
    }
  ];

  sortOption: ListTableSortOption = {
    column: 'createdAt',
    order: 'desc'
  };

  currentFilter: LoopBackFilter = {
    include: 'categories',
    where: {}
  };

  categories: Category[] = [];

  categoryFilter: AbstractControl;
  publicationDateFilter: AbstractControl;
  mediaCheck: AbstractControl;
  searchWord: AbstractControl;

  filteredCategoryId: number;

  list: Article[] = [];

  @ViewChild('dialog') dialog: DialogComponent;

  constructor(
    public formBuilder: FormBuilder,
    public articleApi: ArticleApi,
    public categoryApi: CategoryApi
  ) {
    super();

    this.categoryFilter = formBuilder.control('');
    this.categoryFilter.valueChanges.subscribe(
      value => this.filterByCategory(value)
    );

    this.publicationDateFilter = formBuilder.control(undefined);
    this.publicationDateFilter.valueChanges.subscribe(
      value => this.filterByPublicationDate(value)
    );

    this.mediaCheck = formBuilder.control('');
    this.mediaCheck.valueChanges.subscribe(
      value => this.doMediaCheck(value)
    );

    this.searchWord = formBuilder.control('');
    this.searchWord.valueChanges.pipe(debounceTime(500)).subscribe(
      value => this.search(value)
    );

    this.categoryApi.find({where: {kind: 'article'}}).subscribe(
      res => this.categories = res,
      err => console.error(err)
    );

    this.init();
  }

  fetchListData(page: number, requireCount: boolean = false): Observable<Article[]> {
    let source: Observable<Article[]>;
    const filter: LoopBackFilter = this.getPagingFilter(_.clone(this.currentFilter), page);
    let countSource: Observable<{count: number}>;

    if (this.filteredCategoryId) {
      source = this.categoryApi.getArticles(this.filteredCategoryId, filter);
      countSource = this.categoryApi.getArticles(this.filteredCategoryId, {
        where: filter.where, fields: 'id'
      }).pipe(map(res => ({ count: res.length })));
    } else {
      source = this.articleApi.find(filter);
      countSource = this.articleApi.count(filter.where);
    }

    return this.fetch(source, countSource, requireCount);
  }

  filterByCategory(id: number) {
    this.filteredCategoryId = id || undefined;
    this.fetchListData(1, true).subscribe();
  }

  filterByPublicationDate(date: string) {
    if (date) {
      this.currentFilter.where.startDate = {lte: date};
      this.currentFilter.where.endDate = {gte: date};
    } else {
      delete this.currentFilter.where.startDate;
      delete this.currentFilter.where.endDate;
    }

    this.fetchListData(1, true).subscribe();
  }

  doMediaCheck(value: string) {
    if (value === 'noimage') {
      this.currentFilter.where.and = [{mediaType: 'image'}, {imageIds: null}];
    } else if (value === 'nosound') {
      this.currentFilter.where.and = [{mediaType: 'image'}, {soundId: null}];
    } else if (value === 'novideo') {
      this.currentFilter.where.and = [{mediaType: 'video'}, {videoId: null}];
    } else {
      delete this.currentFilter.where.and;
    }

    this.fetchListData(1, true).subscribe();
  }

  search(word: string) {
    if (word) {
      this.currentFilter.where.or = [{title: {like: `%${word}%`}}, {content: {like: `%${word}%`}}];
    } else {
      delete this.currentFilter.where.or;
    }

    this.fetchListData(1, true).subscribe();
  }

  deleteSelected() {
    this.dialog.show().subscribe(
      result => {
        if (result) {
          super.deleteSelected();
        }
      }
    );
  }

  deleteById(id: number): Observable<any> {
    return this.articleApi.deleteById(id);
  }
}
