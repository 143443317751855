import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { CategoryApi, Category, Article } from 'loopback';
import * as _ from 'lodash';
import { ckeditorConfig } from '../common.constants';
import { GlobalEventService } from '../services/globalEvent.service';
import { switchMap, map } from 'rxjs/operators';

export abstract class ArticleDetailComponentBase<ArticleType> {

  editorConfig = ckeditorConfig;

  form: FormGroup;

  allCategories: Category[] = [];

  categories: Category[] = [];

  alerts = [];

  formBuilder: FormBuilder;
  route: ActivatedRoute;
  router: Router;
  globalEvent: GlobalEventService;
  categoryApi: CategoryApi;

  init() {
    this.form = this.formBuilder.group({
      id: [''],
      title: ['', Validators.required],
      content: [''],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      displayTime: [60, Validators.required],
      mediaType: ['image'],
      buttonText: [''],
      url: ['']
    });

    this.route.params.subscribe(params => {
      if (params['id']) {
        let id = params['id'];
        this.fetchItem(id);
        this.form.controls['id'].setValue(id);
      } else {
        this.setFormValue(this.createNewModel());
      }
    });

    this.fetchCategories();
  }

  abstract fetchItem(id: number);
  abstract fetchCategories();
  abstract createNewModel(): ArticleType;

  addCategory(category: Category) {
    this.categories.push(category);
  }

  removeCategory(category: Category) {
    let currentValue = this.categories;
    let index = currentValue.indexOf(category);
    currentValue.splice(index, 1);
  }

  setFormValue(item: ArticleType) {
    for (let prop in item) {
      if (!item.hasOwnProperty(prop)) continue;

      let control: AbstractControl = this.form.controls[prop];
      let value: any = item[prop];

      switch (prop) {
        case 'startDate':
        case 'endDate':
        value = value ? value.split('T')[0] : value;
        break;

        case 'categories':
        this.categories = value;
        break;

        default:
        break;
      }

      if (control) {
        control.setValue(value);
      }
    }
  }

  submit(e) {
    e.preventDefault();

    if (this.form.valid) {
      let values = _.clone(this.form.value);
      let categories = this.categories;
      let isNew = false;

      if (values.hasOwnProperty('id') && !values.id) {
        isNew = true;
        delete values.id;
      }

      this.preSubmit(values);

      let sequence = this.upsert(values);

      if (!isNew) {
        sequence = sequence.pipe(
          switchMap(res => this.deleteCategories(res.id).pipe(map(() => res)))
        );
      }

      sequence = sequence.pipe(
        switchMap(res => {
          let linkers = [];

          for (let category of categories) {
            linkers.push(this.linkCategory(res.id, category.id));
          }

          return forkJoin.apply(Observable, linkers).pipe(map(() => res));
        })
      );

      sequence.subscribe(
        res => {
          let route: any[] = this.getAfterSubmitRoute(res.id);
          let message = isNew ? 'Successfully created.' : 'Successfully updated.';

          this.globalEvent.trigger('scrollToTop', 500);
          this.globalEvent.trigger('showGlobalToast', {type: 'success', message});

          if (isNew) {
            this.router.navigate(route);
          }
        },
        err => console.error(err)
      );
    } else {
      console.error(this.form);
    }
  }

  abstract preSubmit(values: any): any;
  abstract upsert(values: any): Observable<any>;
  abstract deleteCategories(id: number): Observable<any>;
  abstract linkCategory(itemId: number, categoryId: number): Observable<any>;
  abstract getAfterSubmitRoute(id: number): any[];

  closeAlert(i: number) {
    this.alerts.splice(i, 1);
  }
}
