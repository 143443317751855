import { Component, ViewChild, ViewChildren, ElementRef, QueryList, Inject, LOCALE_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { ChannelApi, TimeTableApi, CategoryApi } from 'loopback';
import * as _ from 'lodash';
import { TvProgramEditorBase } from '../../classes/tvProgramEditorBase.class';
import { ModalComponent } from 'common/components/modal/modal.component';
import { ChannelContentsListComponent } from 'common/components/channelContentsList/channelContentsList.component';
import { FullCalendarComponent } from 'common/components/fullCalendar/fullCalendar.component';
import { DialogComponent } from 'common/components/dialog/dialog.component';
import { ChannelHelperService } from 'common/services/channelHelper.service';

@Component({
  selector: 'pb-editor-tv-program-advance',
  templateUrl: 'tvProgramAdvance.editor.component.html',
  styleUrls: ['tvProgramAdvance.editor.component.scss']
})
export class TvProgramAdvanceEditorComponent extends TvProgramEditorBase {

  @ViewChild('programEditorModal') programEditorModal: ModalComponent;
  @ViewChild('contentsListModal') contentsListModal: ModalComponent;
  @ViewChild('contentsList') contentsList: ChannelContentsListComponent;
  @ViewChildren('calendars') calendars: QueryList<FullCalendarComponent>;
  @ViewChild('bannerCalendar') bannerCalendar: FullCalendarComponent;
  @ViewChild('programDeletionConfirm') programDeletionConfirm: DialogComponent;
  @ViewChild('wholeDayDeletionConfirm') wholeDayDeletionConfirm: DialogComponent;
  @ViewChild('dayMenuToggle') dayMenuToggle: ElementRef;

  constructor(
    public formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public channelApi: ChannelApi,
    public timeTableApi: TimeTableApi,
    public categoryApi: CategoryApi,
    public helper: ChannelHelperService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    super();
  }
}
