import { Component } from '@angular/core';

import { GlobalStateService } from 'common/services/globalState.service';

@Component({
  selector: 'pb-content-top',
  templateUrl: 'contentTop.component.html',
  styleUrls: ['contentTop.component.scss']
})
export class ContentTopComponent {

  public activePageTitle: string = '';

  constructor(private state: GlobalStateService) {
    this.state.onChanged('menu.activeLink').subscribe((changes) => {
      if (changes.currentValue) {
        setTimeout(() => this.activePageTitle = changes.currentValue.title);
      }
    });
  }
}
