import { FormGroup, FormControl } from '@angular/forms';
import { Observable, from, of, forkJoin } from 'rxjs';
import { LoopBackFilter } from 'loopback';
import { ListTableColumn } from '../interfaces/listTableColumn.interface';
import { mergeAll, map, count, tap, mergeMapTo, mapTo, mergeMap } from 'rxjs/operators';

export abstract class ListComponentBase {
  columns: ListTableColumn[];
  list: any[];
  currentFilter: LoopBackFilter = {};

  totalItems: number = 0;
  itemsPerPage: number = 20;
  currentPage: number = 1;
  paginationMaxSize: number = 5;

  itemSelectionForm: FormGroup = new FormGroup({
    selectAll: new FormControl(false),
    items: new FormGroup({})
  });

  get pageLast() {
    return Math.min(this.totalItems, this.itemsPerPage * this.currentPage);
  }

  get itemSelected(): FormGroup {
    return this.itemSelectionForm.get('items') as FormGroup;
  }

  get selectAll(): FormControl {
    return this.itemSelectionForm.get('selectAll') as FormControl;
  }

  init() {
    this.selectAll.valueChanges.subscribe(
      value => {
        const controls = this.itemSelected.controls;
        for (const id in controls) {
          if (!controls[id]) continue;
          controls[id].setValue(value);
        }
      }
    );
  }

  onSortChanged(e) {
    const column = e.column;
    const prop: string | string[] = column.sortProperty || column.property;

    if (prop instanceof Array) {
      this.currentFilter.order = prop.map(p => `${p} ${e.order}`);
    } else if (prop) {
      this.currentFilter.order = `${prop} ${e.order}`;
    }

    this.fetchListData(1, true).subscribe();
  }

  getPagingFilter(filter: LoopBackFilter, page: number): LoopBackFilter {
    filter.limit = this.itemsPerPage;
    filter.skip = (page - 1) * this.itemsPerPage;

    return filter;
  }

  pageChanged(pagingEvent: any) {
    this.fetchListData(pagingEvent.page).subscribe();
  }

  fetch(source: Observable<any[]>, countSource: Observable<any>, requireCount: boolean = false): Observable<any> {
    const counter = requireCount ? countSource.pipe(tap(res => this.totalItems = res.count)) : of(undefined);

    return forkJoin([
      counter,
      source.pipe(
        tap(res => {
          this.selectAll.reset(false);
          this.itemSelected.reset({});
          res.forEach(item => this.itemSelected.addControl(item.id, new FormControl(false)));

          this.list = res;
        })
      )
    ]).pipe(mergeMap(res => res[1]));
  }

  deleteSelected() {
    const selected = [];
    const checkboxValues = this.itemSelected.value;

    for (const id in checkboxValues) {
      if (!checkboxValues[id]) continue;
      selected.push(id);
    }

    from(selected).pipe(
      map(id => this.deleteById(parseInt(id))),
      mergeAll(),
      count()
    ).subscribe(
      res => this.fetchListData(1, true).subscribe()
    );
  }

  abstract fetchListData(page: number, requireCount?: boolean): Observable<any[]>;
  abstract deleteById(id: number);
}
