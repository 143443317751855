import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ArticleListPage } from './pages/articleList/articleList.page';
import { ArticleDetailPage } from './pages/articleDetail/articleDetail.page';
import { ArticleCategoryPage } from './pages/articleCategory/articleCategory.page';
import { AdListPage } from './pages/adList/adList.page';
import { AdDetailPage } from './pages/adDetail/adDetail.page';
import { AdCategoryPage } from './pages/adCategory/adCategory.page';
import { BannerListPage } from './pages/bannerList/bannerList.page';
import { BannerDetailPage } from './pages/bannerDetail/bannerDetail.page';
import { BannerCategoryPage } from './pages/bannerCategory/bannerCategory.page';
import { PreviewPage } from './pages/preview/preview.page';

export const tvRoutes: Routes = [
  { path: 'tv',
    children: [
      { path: 'article',
        children: [
          { path: 'index', component: ArticleListPage },
          { path: 'new', component: ArticleDetailPage },
          { path: 'category', component: ArticleCategoryPage },
          { path: ':id', component: ArticleDetailPage }
        ]
      },
      { path: 'ad',
        children: [
          { path: 'index', component: AdListPage },
          { path: 'new', component: AdDetailPage },
          { path: 'category', component: AdCategoryPage },
          { path: ':id', component: AdDetailPage }
        ]
      },
      { path: 'banner',
        children: [
          { path: 'index', component: BannerListPage },
          { path: 'new', component: BannerDetailPage },
          { path: 'category', component: BannerCategoryPage },
          { path: ':id', component: BannerDetailPage }
        ]
      },
      { path: 'preview', component: PreviewPage }
    ]
  }
];

export const tvRouting: ModuleWithProviders = RouterModule.forChild(tvRoutes);
