import { Component, ViewChild } from '@angular/core';
import { FormBuilder, AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { ChannelApi, Channel, LoopBackFilter } from 'loopback';
import * as _ from 'lodash';
import { ListComponentBase } from 'common/classes/listComponentBase.class';
import { ListTableColumn } from 'common/interfaces/listTableColumn.interface';
import { ListTableSortOption } from 'common/interfaces/listTableSortOption.interface';
import { DialogComponent } from 'common/components/dialog/dialog.component';
import { debounceTime } from 'rxjs/operators';

@Component({
  templateUrl: 'channelList.page.html'
})
export class ChannelListPage extends ListComponentBase {

  columns: ListTableColumn[] = [
    {
      name: 'name',
      title: 'Name',
      sortProperty: 'name'
    },
    {
      name: 'publicChannel',
      title: 'Channel Type',
      sortProperty: 'publicChannel'
    },
    {
      name: 'createdAt',
      title: 'Date created',
      sortProperty: 'createdAt'
    }
  ];

  sortOption: ListTableSortOption = {
    column: 'createdAt',
    order: 'desc'
  };

  searchWord: AbstractControl;

  list: Channel[];

  currentFilter: LoopBackFilter = {
    where: {}
  };

  @ViewChild('dialog') dialog: DialogComponent;

  constructor(
    public formBuilder: FormBuilder,
    public channelApi: ChannelApi
  ) {
    super();

    this.searchWord = formBuilder.control('');
    this.searchWord.valueChanges.pipe(debounceTime(500)).subscribe(
      value => this.search(value)
    );

    this.init();
  }

  fetchListData(page: number, requireCount: boolean = false): Observable<Channel[]> {
    let source: Observable<Channel[]>;
    const filter: LoopBackFilter = this.getPagingFilter(_.clone(this.currentFilter), page);
    let countSource: Observable<{count: number}>;

    source = this.channelApi.find(filter);
    countSource = this.channelApi.count(filter.where);

    return this.fetch(source, countSource, requireCount);
  }

  search(word: string) {
    if (word) {
      this.currentFilter.where.or = [{name: {like: `%${word}%`}}];
    } else {
      delete this.currentFilter.where.or;
    }

    this.fetchListData(1, true).subscribe();
  }

  deleteSelected() {
    this.dialog.show().subscribe(
      result => {
        if (result) {
          super.deleteSelected();
        }
      }
    );
  }

  deleteById(id: number): Observable<any> {
    return this.channelApi.deleteById(id);
  }
}
