import { NgModule } from '@angular/core';

import { PbCommonModule } from 'common/common.module';

import { tvRouting } from './tv.routes';

import { DYNAMIC_CHANNEL_EDITORS } from 'common/tokens';
import { ArticleListPage } from './pages/articleList/articleList.page';
import { ArticleDetailPage } from './pages/articleDetail/articleDetail.page';
import { ArticleCategoryPage } from './pages/articleCategory/articleCategory.page';
import { AdListPage } from './pages/adList/adList.page';
import { AdDetailPage } from './pages/adDetail/adDetail.page';
import { AdCategoryPage } from './pages/adCategory/adCategory.page';
import { BannerListPage } from './pages/bannerList/bannerList.page';
import { BannerDetailPage } from './pages/bannerDetail/bannerDetail.page';
import { BannerCategoryPage } from './pages/bannerCategory/bannerCategory.page';
import { PreviewPage } from './pages/preview/preview.page';
import { TvProgramAdvanceEditorComponent } from './editors/tvProgramAdvance/tvProgramAdvance.editor.component';
import { TvProgramBasicEditorComponent } from './editors/tvProgramBasic/tvProgramBasic.editor.component';

@NgModule({
  imports: [
    PbCommonModule,
    tvRouting
  ],
  exports: [
    PbCommonModule
  ],
  declarations: [
    ArticleListPage, ArticleDetailPage, ArticleCategoryPage,
    AdListPage, AdDetailPage, AdCategoryPage,
    BannerListPage, BannerDetailPage, BannerCategoryPage,
    PreviewPage,
    TvProgramAdvanceEditorComponent,
    TvProgramBasicEditorComponent
  ],
  entryComponents: [
    TvProgramAdvanceEditorComponent,
    TvProgramBasicEditorComponent
  ],
  providers: [
    {
      provide: DYNAMIC_CHANNEL_EDITORS,
      useValue: { name: 'tvProgramAdvance', component: TvProgramAdvanceEditorComponent },
      multi: true
    },
    {
      provide: DYNAMIC_CHANNEL_EDITORS,
      useValue: { name: 'tvProgramBasic', component: TvProgramBasicEditorComponent },
      multi: true
    }
  ]
})
export class PbTvPluginModule {}
