import { Injectable } from '@angular/core';

@Injectable()
export class ThemePreloaderService {

  private loaders: Array<Promise<any>> = [];

  registerLoader(method: Promise<any>) {
    this.loaders.push(method);
  }

  clear() {
    this.loaders = [];
  }

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.executeAll(resolve);
    });
  }

  private executeAll(done: Function) {
    setTimeout(() => {
      Promise.all(this.loaders).then((values) => {
        done.call(undefined, values);

      }).catch((error) => {
        console.error(error);
      });
    });
  }
}
