/* tslint:disable */
import {
  FileInfo,
  TimeTable
} from '../index';

declare var Object: any;
export interface ChannelInterface {
  "name": string;
  "publicChannel"?: boolean;
  "slug"?: string;
  "externalUrl"?: string;
  "forceMute"?: boolean;
  "id"?: number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "imageId"?: number;
  image?: FileInfo;
  contentTimeline?: TimeTable[];
  bannerTimeline?: TimeTable[];
}

export class Channel implements ChannelInterface {
  "name": string;
  "publicChannel": boolean;
  "slug": string;
  "externalUrl": string;
  "forceMute": boolean;
  "id": number;
  "createdAt": Date|string;
  "updatedAt": Date|string;
  "imageId": number;
  image: FileInfo;
  contentTimeline: TimeTable[];
  bannerTimeline: TimeTable[];
  constructor(data?: Partial<ChannelInterface>) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Channel`.
   */
  public static getModelName() {
    return "Channel";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Channel for dynamic purposes.
  **/
  public static factory(data: ChannelInterface): Channel{
    return new Channel(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Channel',
      plural: 'Channels',
      path: 'Channels',
      idName: 'id',
      properties: {
        "name": {
          name: 'name',
          type: 'string'
        },
        "publicChannel": {
          name: 'publicChannel',
          type: 'boolean',
          default: false
        },
        "slug": {
          name: 'slug',
          type: 'string'
        },
        "externalUrl": {
          name: 'externalUrl',
          type: 'string'
        },
        "forceMute": {
          name: 'forceMute',
          type: 'boolean'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date|string'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date|string'
        },
        "imageId": {
          name: 'imageId',
          type: 'number'
        },
      },
      relations: {
        image: {
          name: 'image',
          type: 'FileInfo',
          model: 'FileInfo',
          relationType: 'belongsTo',
                  keyFrom: 'imageId',
          keyTo: 'id'
        },
        contentTimeline: {
          name: 'contentTimeline',
          type: 'TimeTable[]',
          model: 'TimeTable',
          relationType: 'hasMany',
          modelThrough: 'ContentTimeline',
          keyThrough: 'timeTableId',
          keyFrom: 'id',
          keyTo: 'channelId'
        },
        bannerTimeline: {
          name: 'bannerTimeline',
          type: 'TimeTable[]',
          model: 'TimeTable',
          relationType: 'hasMany',
          modelThrough: 'BannerTimeline',
          keyThrough: 'timeTableId',
          keyFrom: 'id',
          keyTo: 'channelId'
        },
      }
    }
  }
}
